import { SkeletonLoading as SkeletonLoadingInterface } from "../../interfaces/skeletonLoading";

import styles from "./SkeletonLoading.module.scss";

export default function SkeletonLoading({
  repetitions,
  gap,
  height,
}: SkeletonLoadingInterface) {
  return (
    <>
      {Array(repetitions)
        .fill(true)
        .map((item, index) => (
          <div
            className={`${styles.rect}`}
            style={{
              marginBottom: gap,
              height,
            }}
            key={index}
          ></div>
        ))}
    </>
  );
}
