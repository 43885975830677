import { NavLink } from "react-router-dom";
import styles from "./ProfileSettings.module.scss";
import ToggleNotifications from "./ToggleNotifications";
import notification from "../../assets/icons/notification/notification.svg";
import language from "../../assets/icons/profile/language.svg";
import help from "../../assets/icons/profile/help.svg";
import contact from "../../assets/icons/profile/contact-us.svg";
import privacy from "../../assets/icons/profile/privacy.svg";
import profile from "../../assets/icons/profile/profile.svg";
import userManagement from "../../assets/icons/profile/userIcons.svg";
import logout from "../../assets/icons/profile/log-out.svg";
import { useUser } from "../../useContext/UserContext";

interface ProfileSettingProps {
  selectedLanguage: string;
  isNotificationsOn: boolean;
  onNotificationToggle: (value: boolean) => void;
  isMobile?: boolean;
  onMenuClick: (item: string) => void;
}

export default function ProfileSettings({
  selectedLanguage,
  isNotificationsOn,
  onNotificationToggle,
  isMobile = false,
  onMenuClick,
}: ProfileSettingProps) {
  const { user } = useUser();

  const handleNotificationClick = () => {
    onNotificationToggle(!isNotificationsOn);
  };

  const handleLogout = (): void => {
    sessionStorage.clear();
  };

  return (
    <>
      <div className={styles["profile"]}>
        <h2 className={styles["profile__heading"]}>GENERAL SETTINGS</h2>
        <div className={styles["profile__list"]}>
          <NavLink
            end
            to={isMobile ? "/profile/view-profile" : "/profile"}
            className={({ isActive }) =>
              `${styles["profile__list__item"]} ${
                !isMobile && isActive
                  ? styles["profile__list__item--is-active"]
                  : ""
              }`
            }
            onClick={() => isMobile && onMenuClick("Profile")}
          >
            <img src={profile} alt="Profile" />
            Profile
          </NavLink>

          {user.role === "Farm Manager" && (
            <NavLink
              to="/profile/profile-user-management"
              className={({ isActive }) =>
                `${styles["profile__list__item"]} ${
                  !isMobile && isActive
                    ? styles["profile__list__item--is-active"]
                    : ""
                }`
              }
              onClick={() => isMobile && onMenuClick("User Managem  ent")}
            >
              <img src={userManagement} alt="User Management" />
              User Management
            </NavLink>
          )}

          <NavLink
            to="/profile/profile-notifications"
            className={({ isActive }) =>
              `${styles["profile__list__item"]} ${
                !isMobile && isActive
                  ? styles["profile__list__item--is-active"]
                  : ""
              }`
            }
            onClick={() => isMobile && onMenuClick("Notifications")}
          >
            <img src={notification} alt="Notification" />
            Notification
            <div className={styles["toggle"]} onClick={handleNotificationClick}>
              <ToggleNotifications
                checked={isNotificationsOn}
                onChange={handleNotificationClick}
              />
            </div>
          </NavLink>

          <NavLink
            to="/profile/profile-languages"
            className={({ isActive }) =>
              `${styles["profile__list__item"]} ${
                !isMobile && isActive
                  ? styles["profile__list__item--is-active"]
                  : ""
              }`
            }
            onClick={() => isMobile && onMenuClick("Languages")}
          >
            <img src={language} alt="Language" />
            Language
            <span>{selectedLanguage}</span>
          </NavLink>
        </div>
      </div>

      <div className={styles["profile"]}>
        <h2 className={styles["profile__heading"]}>Privacy and Support</h2>
        <ul className={styles["profile__list"]}>
          <li className={styles["profile__list__item"]}>
            <img src={help} alt="Help" />
            Help & Support
          </li>
          <li className={styles["profile__list__item"]}>
            <img src={contact} alt="Contact" />
            Contact Us
          </li>
          <li className={styles["profile__list__item"]}>
            <img src={privacy} alt="Privacy" />
            Privacy Policy
          </li>
        </ul>
      </div>

      <div className={styles["profile__logout"]}>
        <a
          href="/login"
          className={styles["profile__logout-link"]}
          onClick={handleLogout}
        >
          <img src={logout} alt="Logout" />
          Log Out
        </a>
      </div>
    </>
  );
}
