import { useEffect, useContext } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

import styles from "../../styles/SuccessfuImport.module.scss";
import Successful from "../../assets/icons/successful.svg";
import type { SetupProps } from "../../types";
import { FormButton } from "../form/Form";
type TSuccessfulImport = SetupProps;

const SuccesfulImport: React.FC<TSuccessfulImport> = ({ page, changePage }) => {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  return (
    <div className={styles["successful-import"]}>
      <div className={styles["successful-import__heading"]}>
        <h2>
          Field Import was Successful
        </h2>
        <p>
          <Trans id="successfulImport.subheading">
            You can later also add fields to farm
          </Trans>
        </p>
      </div>
      <div className={styles["successful-import__body"]}>
        <span className={styles["successful-import__body__item"]}>
          <img src={Successful} alt="successful" /> Ausleben Nord
        </span>
        <span className={styles["successful-import__body__item"]}>
          <img src={Successful} alt="successful" />
          <span>Ausleben Hoetensleben</span>
        </span>
        <span className={styles["successful-import__body__item"]}>
          <img src={Successful} alt="successful" /> Ausleben-Waesleben West
        </span>
        <FormButton>Access Your Farm</FormButton>
      </div>
    </div>
  );
};

export default SuccesfulImport;
