import styles from "../../styles/FarmSetupInfo.module.scss";
import SetupHeading from "./SetupHeading";
import type { SetupProps } from "../../types";
import { Form, FormGroup, FormInput } from "../form/Form";
import { Trans } from "@lingui/react";
import { LocaleContext } from "../../useContext/localeContext";
import { useContext, useEffect , useState } from "react";
import { FormTextarea } from "../form/Form";
import useIsMobile from "../../hooks/useIsMobile";

type TFarmInfoProps = SetupProps;

const FarmSetupInfo: React.FC<TFarmInfoProps> = ({ page, changePage }) => {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  const isMobile = useIsMobile();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const [farmName,setFarmName] = useState("Max Mustermann Farm");
  const [description, setDescription] = useState("Ausleben Farm NZ");

  return (
    <div className={styles["setup-info"]}>
      <SetupHeading page={page} changePage={changePage} headingTitle="Check Your Farm Information" headingSubtitle="Let’s start with some core information about your farm"/>
      <Form
        onSubmit={handleSubmit}
        customClassName="farm-setup-form"
        customCSS={{ marginTop: "2rem"}}
      >
        <FormGroup isRow>
          <FormInput
            id="farm-name"
            name="farm-name"
            label="FARM NAME*"
            value={farmName}
            onChange={(e) => setFarmName(e.target.value)}
            customCSS={{ color: "rgba(13, 24, 20, 0.5)" }}
          />
        </FormGroup>
        <FormGroup>
          <FormTextarea
            id="description"
            name="description"
            label="DESCRIPTION*"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            customCSS={{ color: "rgba(13, 24, 20, 0.5)" }}
          />
        </FormGroup>
      </Form>
      <button
        onClick={() => changePage({ page, isForward: true })}
        className="form__button"
      >
        <Trans id="farmSetupInfo.continueButton">Continue</Trans>
      </button>
    </div>
  );
};

export default FarmSetupInfo;
