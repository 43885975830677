import { FormButton, FormSelect } from "../components/form/Form";

import SectionContentGroup from "../components/section/SectionContentGroup";
import CustomCalendar from "../components/calendar/CustomCalendar";
import styles from "../styles/FieldDetailsTask.module.scss";
import taskIcon from "../assets/icons/files.svg";

import pinIcon from "../assets/icons/pin.svg";
import Modal from "../components/modal/Modal";
import NewTaskForm from "../components/form/NewTaskForm";
import { useState } from "react";

export default function FieldDetailsInformation() {
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);

  return (
    <>
      <SectionContentGroup
        icon={pinIcon}
        title="Grapes Fields"
        actions={
          <>
            <FormButton
              type="button"
              isTransparent={true}
              customCSS={{
                padding: "0.8rem 3rem",
                fontSize: "var(--fs-xxs)",
              }}
              action={() => setIsNewTaskModalOpen(true)}
            >
              New Task
            </FormButton>
          </>
        }
        customCSS={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "stretch",
          alignItems: "stretch",
          padding: "0px",
          height: "100%",
          border: "0px",
        }}
      >
        <CustomCalendar
          defaultView="day"
          disableCustomFilters={false}
          showOnlyViewFilter={true}
          customClassName={styles["view-filter"]}
          tasks={[]}
        />
      </SectionContentGroup>
      <Modal
        headingTitle="Create New Task"
        isOpen={isNewTaskModalOpen}
        headingIcon={taskIcon}
        onClose={() => setIsNewTaskModalOpen(false)}
      >
        <NewTaskForm
          onSuccess={() => {
            setIsNewTaskModalOpen(false);
          }}
          onClose={() => setIsNewTaskModalOpen(false)}
        />
      </Modal>
    </>
  );
}
