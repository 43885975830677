import { stringify } from "querystring";

const BASE_URL = "msgbuild-os73gm1h";
const CLIENT_ID = "sb-farming-prod-eu10!b509056|farming!b120273";
const CLIENT_SECRET =
  "01eb8aca-0a93-48c5-9d80-5ad07c064e12$V_ujMHvqA2sFdj1ECUcxb7UCy_RWCDe-aJuK0FK9WyE=";

/**
 * Function is used to fetch resources from API.
 * @param path Path to API resource.
 * @param region Region.
 * @returns {Promise<JSON | null>} Fetched resource or null.
 */
export async function useFetch({
  path,
  method,
  body,
}: {
  path?: string;
  method?: string;
  body?: string;
}) {
  // We need to get Bearer token from session storage
  const accessToken = sessionStorage.getItem("accessToken");

  // If Bearer token does not exist, we need to redirect user to login page.
  if (!accessToken) return location.replace("/login");

  try {
    const data = await fetch(`/api${path}`, {
      method,
      headers: {
        Authorization: `Bearer ${JSON.parse(accessToken).access_token}`,
        "Content-Type": "application/json",
      },
      body
    });
    const response = await data.json();

    return response;
  } catch (error) {
    console.log(error);
  }
}
