import { Calendar, momentLocalizer, View } from "react-big-calendar";
import moment from "moment";
import MonthlyEvent from "./MonthlyEvent";
import WeeklyEvent from "./WeeklyEvent";

import "react-big-calendar/lib/sass/styles.scss";
import "./CustomCalendar.scss";

import pinIcon from "../../assets/icons/pin.svg";
import Toolbar from "./Toolbar";
import CalendarFilters from "./CalendarFilters";
import { ChangeEvent, useEffect, useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import AgendaEvent from "./AgendaEvent";
import { useNavigate } from "react-router-dom";

interface TaskList {
  id: number;
  title: string;
  start: Date;
  end: Date;
  priority: string;
  resourcesId: number;
  assignee: string;
  status: string;
}

const resources = [
  { id: 1, title: "John Doe", location: "Giri Purno" },
  { id: 2, title: "John Boo", location: "Giri Purno" },
  { id: 3, title: "John Deo", location: "Giri Purno" },
  { id: 4, title: "Jenny White", location: "Giri Purno" },
  { id: 5, title: "Jake Davis", location: "Giri Purno" },
];

const CustomResourceHeader = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => {
  return (
    <>
      <span className="rbc-header-title">{title}</span>
      {location && (
        <span className="rbc-header-subtitle">
          <img src={pinIcon} alt={subtitle} />
          {subtitle}
        </span>
      )}
    </>
  );
};

const CustomWeekday = ({ label, date }: { label: string; date: Date }) => {
  return (
    <>
      <span>{label.split(" ")[1]}</span>
      <span>{label.split(" ")[0]}</span>
    </>
  );
};

interface CustomCalendarProps {
  defaultView?: string;
  disableCustomFilters?: boolean;
  tasks: TaskList[];
  onEditTask?: (task: TaskList) => void;
  showOnlyViewFilter?: boolean;
  customClassName?: string;
}

export default function CustomCalendar({
  defaultView = "month",
  disableCustomFilters = false,
  tasks,
  onEditTask,
  showOnlyViewFilter = false,
  customClassName = "",
}: CustomCalendarProps) {
  const localizer = momentLocalizer(moment);
  const [filteredTasks, setFilteredTasks] = useState<TaskList[]>([]);
  const [view, setView] = useState<View>(defaultView as View);
  const [assignee, setAssignee] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleViewChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    if (isMobile && value.toLowerCase() === "month") return;

    setView(value.toLocaleLowerCase() as View);
  };

  const handleAssigneeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setAssignee([...assignee, value]);
    } else {
      setAssignee(assignee.filter((itemValue) => itemValue !== value));
    }
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setStatus([...status, value]);
    } else {
      setStatus(status.filter((itemValue) => itemValue !== value));
    }
  };

  useEffect(() => {
    const newFilterTasks = tasks.filter(
      (task) =>
        (assignee.length ? assignee.includes(task.assignee) : true) &&
        (status.length ? status.includes(task.status) : true)
    );
    setFilteredTasks(newFilterTasks);
  }, [assignee, status, tasks]);

  useEffect(() => {
    if (defaultView !== "month") return;

    if (isMobile) {
      setView("day");
    }
  }, []);

  return (
    <>
      {!disableCustomFilters && (
        <CalendarFilters
          view={(view.charAt(0).toUpperCase() + view.slice(1)) as string}
          setView={handleViewChange}
          assigneeList={Array.from(new Set(tasks.map((task) => task.assignee)))}
          setAssignee={handleAssigneeChange}
          statusList={Array.from(new Set(tasks.map((task) => task.status)))}
          setStatus={handleStatusChange}
          showOnlyViewFilter={showOnlyViewFilter}
          customClassName={customClassName}
        />
      )}
      <Calendar
        defaultView={view}
        view={view}
        views={["month", "week", "day", "agenda"]}
        onView={(newView: View) => {
          setView(newView);
        }}
        resources={resources}
        resourceIdAccessor="id"
        resourceAccessor={"id"}
        resourceTitleAccessor="title"
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={filteredTasks}
        timeslots={1}
        step={60}
        min={new Date(2025, 0, 1, 6, 0)}
        components={{
          header: CustomWeekday,
          toolbar: (props) => {
            return <Toolbar {...props} />;
          },
          resourceHeader: ({ resource }) => (
            <CustomResourceHeader
              title={resource.title}
              subtitle={resource.location}
            />
          ),
          month: {
            event: ({ event }) => (
              <MonthlyEvent
                {...event}
                onEdit={onEditTask}
              />
            ),
          },
          week: {
            event: ({ event }) => (
              <WeeklyEvent {...event} onEdit={onEditTask} />
            ),
          },
          day: {
            event: ({ event }) => (
              <WeeklyEvent {...event} onEdit={onEditTask} />
            ),
          },
          agenda: {
            event: ({ event }) => (
              <AgendaEvent {...event} onEdit={onEditTask} />
            ),
          },
        }}
      />
    </>
  );
}
