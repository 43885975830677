import React, { useState, useEffect } from "react";
import styles from "../../styles/FarmSetupFields.module.scss";
import arrDown from "../../assets/icons/arrDown.svg";
import arrUp from "../../assets/icons/tasks/closeup.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import redDanger from "../../assets/icons/warning.svg";
import susccessIcon from "../../assets/icons/successful.svg";
import SingleFieldItems from "./SingleFieldItems";
import { FormButton } from "../form/Form";
import Modal from "../modal/Modal";

interface Field {
  id: number;
  name: string;
  isChecked: boolean;
}

interface SingleFieldProps {
  field: Field;
  onChange: (id: number) => void;
  onDelete: (id: number) => void;
}

const SingleField: React.FC<SingleFieldProps> = ({
  field,
  onChange,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConformDeleteOpen, setIsConformDeleteOpen] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleDeleteClick = () => {
    setIsConformDeleteOpen(false);
    setIsDeleteModalOpen(true);
  };

  const fieldDelete = () => {
    onDelete(field.id);
    setIsConformDeleteOpen(true);
  };

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };


  return (
    <div className={styles["single-field"]} onClick={toggleAccordion}>
      <input
        className={styles["single-field__checkbox"]}
        name="field"
        type="checkbox"
        checked={field.isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={() => onChange(field.id)}
      />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" />
        <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
      </svg>
      <div className={styles["form__input"]}>
        <span className={styles["single-field__accordion-heading"]}>
          <h2>{field.name}</h2>
          <div className={styles["single-field__heading-controls"]}>
            <button type="button">
              <img
                src={isOpen ? arrUp : arrDown}
                className={styles["single-field__accordion-arrow"]}
                alt={isOpen ? "Arr Up" : "Arr Down"}
              />
            </button>
            <img
              onClick={() => setIsConformDeleteOpen(true)}
              className={styles["single-field__delete"]}
              src={deleteIcon}
              alt="Delete"
            />
          </div>
        </span>
        <div
          onClick={(e) => e.stopPropagation()}
          className={`${styles["single-field__is-closed"]} ${
            isOpen ? styles["single-field__is-opened"] : ""
          }`}
        >
          {isOpen && <SingleFieldItems />}
        </div>
      </div>
      <Modal
        headingTitle=""
        showCloseButton={false}
        onClose={() => setIsConformDeleteOpen(false)}
        isOpen={isConformDeleteOpen}
        size="md"
      >
        <div className={styles["modal__content"]}>
          <img src={redDanger} alt="" />
          <h2 className={styles["modal__content__title"]}>
            Are you sure you want to delete the field?
          </h2>
          <p className={styles["modal__content__message"]}>
            This action cannot be undone.
          </p>
          <div className={styles["modal__content__actions"]}>
            <FormButton
              isTransparent={true}
              action={(e) => {
                e.stopPropagation;
                setIsConformDeleteOpen(false);
              }}
            >
              No
            </FormButton>
            <FormButton
              customCSS={{ height: "100%" }}
              action={handleDeleteClick}
            >
              Yes
            </FormButton>
          </div>
        </div>
      </Modal>
      <Modal
        headingTitle=""
        onClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
        size="md"
        showCloseButton={false}
      >
        <div className={styles["success-modal-content"]}>
          <img src={susccessIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>Field Deleted!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully deleted the Field.{" "}
            <span className={styles["success-modal__fieldname"]}>
              {field.name}
            </span>
          </p>
          <FormButton
            action={() => {
              fieldDelete();
              setIsDeleteModalOpen(false);
            }}
          >
            Got it!
          </FormButton>
        </div>
      </Modal>
    </div>
  );
};

export default SingleField;
