import { ChangeEvent, useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import SkeletonLoading from "../other/SkeletonLoading";

import { Task as TaskInterface } from "../../interfaces/task";

import locationIcon from "../../assets/icons/pin.svg";

import styles from "../../styles/TaskList.module.scss";

import Modal from "../modal/Modal";
import { FormButton } from "../form/Form";
import taskAssignedIcon from "../../assets/icons/taskAssigned.svg";
import pencilIcon from "../../assets/icons/edit.svg";
import copyIcon from "../../assets/icons/copyIcon.svg";

export const initialTasks=[];

export function TaskList({
  tasks,
  isOverdue = false,
  showCheckbox = true,
  showDate = false,
  filter = [],
  showStatusBar = true,
}: {
  tasks: TaskInterface[];
  isOverdue?: boolean;
  showCheckbox?: boolean;
  showDate?: boolean;
  filter?: string[];
  showStatusBar?: boolean;
}) {
  const [initialTasks, setInitialTasks] = useState<TaskInterface[]>(tasks);
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const formatDate = (deadline: string) => {
    const date = new Date(deadline);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    return { month, day };
  };

  const checkOverdue = (tasks: TaskInterface[]) => {
    const currentDate = new Date();
    return tasks.map((task) => {
      const taskDeadline = new Date(task.endDate);
      if (currentDate > taskDeadline && task.status !== "COMPLETED") {
        return {
          ...task,
          status: "OVERDUE",
        };
      }
      return task;
    });
  };

  useEffect(() => {
    setInitialTasks(() => checkOverdue(tasks));
  }, [tasks]);

  const handleCheckbox = (
    taskId: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newTasks = initialTasks.map((task) => {
      if (task.ID === taskId) {
        return {
          ...task,
          status: event.target.checked ? "COMPLETED" : "IN_PROGRESS",
        } as TaskInterface;
      }
      return task;
    });
    setInitialTasks(newTasks);


    const checked = event.target.checked;
    setIsChecked(checked);
    setShowModal(checked);
    setIsModalOpen(true);
  };

  const filteredTasks = initialTasks.filter((task) => {
    const currentDate = new Date();
    const deadline = new Date(task.endDate);

    if (isOverdue) {
      return currentDate > deadline && task.status !== "COMPLETED";
    }

    if (filter.includes("All") || filter.length === 0) {
      return true;
    }

    if (filter.length > 0 && !filter.includes(task.status)) return false;

    return true;
  });

  return (
    <ul
      className={`${styles["task-list"]} ${
        showStatusBar ? "" : `${styles["task-list--without-statusbar"]}`
      }`}
    >
      {filteredTasks.map((task) => {
        const { month, day } = formatDate(task.endDate);
        return (
          <li
            key={task.ID}
            className={`${styles["task-list__item"]} ${styles[`task-list__item--${task.status}`]
              }`}
          >
            {showDate && (
              <div className={`${styles["task-list__date"]}`}>
                <p className={styles["task-list__date__month"]}>{month}</p>
                <p className={styles["task-list__date__day"]}>{day}</p>
              </div>
            )}
            <div
              className={`${styles["task-list__content"]} ${isOverdue && task.status === "OVERDUE"
                  ? styles["task-list__content--OVERDUE"]
                  : styles[`task-list__content--${task.status}`]
              }`}
            >
              {showCheckbox && (
                <div className={styles["task-list__checkbox"]}>
                  <input
                    id={`task-${task.ID}`}
                    name={`task-${task.ID}`}
                    type="checkbox"
                    checked={task.status === "COMPLETED"}
                    onChange={(event) => handleCheckbox(task.ID, event)}
                    aria-label={`Mark task ${task.name} as completed`}
                    disabled={
                      task.status === "COMPLETED" || task.status === "OVERDUE"
                    }
                  />
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="4" />
                    <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
                  </svg>
                </div>
              )}
              <div className={styles["task-list__heading"]}>
                <span className={styles["task-list__heading__title"]}>
                  {task.name}
                </span>
                {task.farm_ID && (
                  <span className={styles["task-list__heading__subtitle"]}>
                    <img src={locationIcon} alt="Location pin" />
                    {task.farm_ID}
                  </span>
                )}
              </div>
              {showStatusBar && (
                <div
                  className={`${styles["task-list__status"]} ${
                    styles[`task-list__status--${task.status}`]
                  }`}
                ></div>
              )}
              <div
                style={
                  showStatusBar
                    ? {}
                    : {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        gap: "var(--spacing-xs)",
                      }
                }
              >
                {!showStatusBar && (
                  <div
                    style={{
                      display: "flex",
                      gap: "var(--spacing-xs)",
                    }}
                  >
                    {task.status === "drafted" && (
                      <img src={pencilIcon} alt="Edit" />
                    )}

                    {task.status === "overdue" && (
                      <>
                        <img src={pencilIcon} alt="Edit" />
                        <img src={copyIcon} alt="Copy" />
                      </>
                    )}

                    {task.status === "canceled" && (
                      <img src={copyIcon} alt="Copy" />
                    )}
                  </div>
                )}
              </div>
              <div className={styles["task-list__info-wrapper"]}>
                <div className={styles["task-list__info"]}>
                  <span>{`${new Date(task.createdAt).getHours()}:${new Date(
                    task.createdAt
                  ).getMinutes()}`}</span>
                  {!showStatusBar && <span>|</span>}
                </div>
                <div className={styles["task-list__info"]}>
                  <span>
                    {(
                      Math.abs(
                        new Date(task.createdAt).getTime() -
                        new Date(task.endDate).getTime()
                      ) /
                      (1000 * 60 * 60)
                    ).toFixed(0)}
                    hr
                  </span>
                </div>
              </div>
            </div>
          </li>
        );
      })}
      {!filteredTasks.length && (
        <SkeletonLoading repetitions={5} height="2rem" gap="1rem" />
      )}
      {showModal && (
        <Modal
          headingTitle=""
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          showCloseButton={false}
          customStyle={{
            width: "38rem",
            height: "33rem"
          }}
        >
          <div className={styles["success-modal-content"]}>
            <img src={taskAssignedIcon} alt="Task Assignrd Icon" />
            <h2 className={styles["success-modal-content__title"]}>Task completed!</h2>
            <p className={styles["success-modal-content__description"]}>You have successfully changed <br></br>the status of your Task to <span>Completed.</span></p>
            <FormButton
              children="Got it!"
              action={() => setIsModalOpen(false)}
            />

          </div>
        </Modal>
      )}
    </ul>
  );
}
