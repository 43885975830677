import { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { LocaleContext } from "../../useContext/localeContext";
import {
  Form,
  FormInput,
  FormGroup,
  FormButton,
  FormTextarea,
  FormSelect,
} from "./Form";
import dangerIcon from "../../assets/icons/redDanger.svg";
import styles from "./NewFormTask.module.scss";
import { Trans } from "@lingui/react";
import { validateDates, validateFields } from "./ValidateForms";
import { useFetch } from "../../hooks/useFetch";
import { Task as TaskInterface } from "../../interfaces/task";
import { Priorities as PrioritiesInterface } from "../../interfaces/priorities";
import { Resources as ResourcesInterface } from "../../interfaces/resources";
import { TaskOperations as TaskOperationsInterface } from "../../interfaces/taskOperations";
import { TaskTypes as TaskTypesInterface } from "../../interfaces/taskTypes";
import { FarmFields as FarmFieldsInterface } from "../../interfaces/farmFields";
import { CropZones as CropZonesInterface } from "../../interfaces/cropZones";
import { Users as UsersInterface } from "../../interfaces/users";

interface NewTaskFormProps {
  onClose: () => void;
  onSuccess: (name: string) => void;
  tasks?: TaskInterface[];
}

export interface TaskType {
  ID: string
  category: string
  createdAt: string
  createdBy: string
  description: string
  modifiedAt: string
  modifiedBy: string
  name: string
}

export default function NewTaskForm(
  { onClose, onSuccess, tasks }: NewTaskFormProps,

) {
  const [formValues, setFormValues] = useState({
    ID:"",
    name:"",
    workOrder:"",
    status:"",
    taskPriority_code:"",
    assignee:"",
    taskType_ID:"",
    taskOperationTypes_ID:"",
    taskInputMaterials:"",
    taskResources:"",
    field:"",
    cropZone:"",
    description:"",
    startDate:"",
    endDate:"",
  });

  const form = useRef(null);

  interface SelectOption {
    name: string,
    value: string
  }

  const [formError, setFormError] = useState("");
  const [invalidField, setInvalidField] = useState<string[]>([]);
  const [priorities, setPriorities] = useState<SelectOption[]>();
  const [resources, setResources] = useState<SelectOption[]>();
  const [taskOperations, setTaskOperations] = useState<SelectOption[]>();
  const [taskTypes, setTaskTypes] = useState<SelectOption[]>();
  const [farmFields, setFarmFields] = useState<SelectOption[]>();
  const [cropZones, setCropZones] = useState<SelectOption[]>();
  const [workOrder, setWorkOrder] = useState<SelectOption[]>();
  const [userName, setUserName] = useState<string[]>();

  const fieldref = useRef<{
    [key: string]: HTMLInputElement | HTMLSelectElement | null;
  }>({});

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setInvalidField((prev) => prev.filter((field) => field !== name));
  };

  // const validateForm = () => {
  //   const { isValid, errorMessage, invalidFieldsArray } =
  //     validateFields(formValues);

  //   const dateValidation = validateDates({
  //     start: formValues.startDate,
  //     end: formValues.endDate,
  //     startField: "startDate",
  //     endField: "endDate",
  //   });

  //   if (!dateValidation.isValid) {
  //     setFormError(dateValidation.errorMessage);
  //     setInvalidField([
  //       ...invalidFieldsArray,
  //       ...dateValidation.invalidFieldsArray,
  //     ]);
  //     return false;
  //   }

  //   setFormError(errorMessage);
  //   setInvalidField(invalidFieldsArray);

  //   if (!isValid && invalidFieldsArray.length > 0) {
  //     fieldref.current[invalidFieldsArray[0]]?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //   }

  //   return isValid;
  // };

  useEffect(() => {
    (async function () {
      const taskPriorities = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/Priorities",
      });
      const taskResources = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/Resources"
      })
      const taskOperations = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/TaskOperations"
      })
      console.log(taskOperations)
      const taskTypes = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/TaskTypes"
      })

      const farmFields = await useFetch({
        path: "/foundation-api/v1/odata/v1/TaskService/Fields"
      })

      const farmCropZone = await useFetch({
        path: "/foundation-api/v1/odata/v1/TaskService/CropZones"
      })

      const workOrder = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/WorkOrders"
      })

      const users = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/Users"
      })

      console.log(taskOperations)

      setPriorities(taskPriorities.value.map((prioritiy: PrioritiesInterface) => ({ name: prioritiy.name, value: prioritiy.code })))
      setResources(taskResources.value.map((resources: ResourcesInterface) => ({ name: resources.name, value: resources.resourceType_ID })))
      setTaskOperations(taskOperations.value.map((taskOperations: TaskOperationsInterface) => ({ name: taskOperations.name, value: taskOperations.taskOperationTypes_ID })))
      setTaskTypes(taskTypes.value.map((task: TaskTypesInterface) => ({ name: task.name, value: task.ID })))
      setFarmFields(farmFields.value.map((farmFields: FarmFieldsInterface) => ({ name: farmFields.name, value: farmFields.ID })))
      setCropZones(farmCropZone.value.map((farmCropZone: CropZonesInterface) => ({ name: farmCropZone.name, value: farmCropZone.ID })))
      //setWorkOrder(workOrder.value.map((workOrder: CropZonesInterface) => ({name:workOrder.name, value:workOrder.ID})))
      setUserName(users.value.map((users: UsersInterface) => users.firstName))

    })();
  }, []);


  async function postData() {
    if (form.current) {
      const formData = new FormData(form.current);
      const data = Object.fromEntries(formData.entries());
      //const sanitazeData = { name: "666", status: "DRAFT", taskType_ID: '01234567-89ab-cdef-0123-456789abcdef' }
 
      const sanitazeData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== "" && value !== null && value !== undefined)
      ) 

          console.log(sanitazeData)
      const response = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/Tasks",
        method: 'POST',
        body: JSON.stringify(sanitazeData)
      })

      //console.log(response)
    }

  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    postData();

    //  if (!validateForm()) {
    //    return;
    //  }

    setFormError("");
    onClose();
    onSuccess(formValues.name);

  };

  const generateId = () => {
    const randomPart = Math.floor(Math.random() * 100000);
    return `${randomPart}`;
  }

  const ID = generateId();

  return (
    <>
      <Form ref={form} customClassName={styles["custom-form"]} onSubmit={handleSubmit}>
        {formError && (
          <div className={styles["custom-form__error-container"]}>
            <p
              className={styles["custom-form__error-container__error-message"]}
            >
              <img src={dangerIcon} alt="" />
              {formError}
            </p>
          </div>
        )}
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <input
            id={ID}
            name="ID"
            value={generateId()}
            type="hidden"
          />
          <FormInput
            id="task-name"
            name="name"
            label="Task Name"
            isRequired={true}
            value={formValues.name}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.name = el)}
            customClassName={
              invalidField.includes("name") ? styles["input-error"] : ""
            }
          />
          {/* <FormSelect
            id="work-order"
            name="workOrder"
            label="Work Order"
            selectOptions={[]}
            selectClassName={
              invalidField.includes("workOrder") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.workOrder}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.workOrder = el)}
          /> */}
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <input
            id="status"
            name="status"
            value="DRAFT"
            onChange={handleInputChange}
          />
          <FormSelect
            id="priority"
            name="taskPriority_code"
            label="Priority"
            selectOptions={priorities}
            selectClassName={
              invalidField.includes("priority") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.taskPriority_code}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.priority = el)}
          />
          <FormSelect
            id="assignee"
            name="assignee"
            label="Assignee"
            //selectOptions={userName}
            selectClassName={
              invalidField.includes("assignee") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.assignee}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.assignee = el)}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="task-type"
            name="taskType_ID"
            label="Task Type"
            selectOptions={taskTypes}
            selectClassName={
              invalidField.includes("taskType") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.taskType_ID}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskType = el)}
          />
          {/* <FormSelect
            id="operations"
            name="operations"
            label="Task Operations"
            selectOptions={taskOperations}
            selectClassName={
              invalidField.includes("taskOperationTypes_ID") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.taskOperationTypes_ID}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskOperationTypes_ID = el)}
          /> */}
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            gridRow: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="task-input-materials"
            name="taskInputMaterials"
            label="Task Input Materials"
            selectOptions={[]}
            selectClassName={
              invalidField.includes("taskInputMaterials")
                ? styles["input-error"]
                : ""
            }
            isRequired={true}
            value={formValues.taskInputMaterials}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskInputMaterials = el)}
          />
          <FormSelect
            id="task-resources"
            name="taskResources"
            label="Task Resources"
            selectOptions={resources}
            isRequired={true}
            value={formValues.taskResources}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskResources = el)}
            selectClassName={
              invalidField.includes("taskResources")
                ? styles["input-error"]
                : ""
            }
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="field"
            name="field"
            label="Field"
            selectOptions={farmFields}
            selectClassName={
              invalidField.includes("field") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.field}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.field = el)}
          />
          <FormSelect
            id="crop-zone"
            name="cropZone"
            label="Crop Zone"
            selectOptions={cropZones}
            selectClassName={
              invalidField.includes("cropZone") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.cropZone}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.cropZone = el)}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            flexDirection: "row",
            margin: 0,
          }}
          customClassName={styles["custom-form__textarea"]}
        >
          <FormTextarea
            id="description"
            name="description"
            label="Description"
            isRequired={true}
            customClassName={
              invalidField.includes("description") ? styles["input-error"] : ""
            }
            value={formValues.description}
            onChange={handleInputChange}
          />
          <FormInput
            id="start-date"
            name="startDate"
            type="date"
            label="Start Date"
            isRequired={true}
            customCSS={{
              flex: 1,
            }}
            customClassName={
              invalidField.includes("startDate") ? styles["input-error"] : ""
            }
            value={formValues.startDate}
            onChange={handleInputChange}
          />
          <FormInput
            id="end-date"
            name="endDate"
            type="date"
            label="End Date"
            isRequired={true}
            customCSS={{
              flex: 1,
            }}
            customClassName={
              invalidField.includes("endDate") ? styles["input-error"] : ""
            }
            value={formValues.endDate}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup
          customClassName={styles["custom-form__button"]}
          customCSS={{
            margin: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormButton action={handleSubmit}>Create Task</FormButton>
        </FormGroup>
      </Form>
    </>
  );
}
