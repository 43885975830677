const AUTH_BASE_URL =
  "https://farmers-app-rcbhth9g.authentication.eu10.hana.ondemand.com";
// const AUTH_BASE_URL = "";
const REDIRECT_URL = encodeURIComponent(
  `${location.protocol}//${location.host}`
);
console.log();
const CLIENT_ID = encodeURIComponent(
  "sb-farming-prod-eu10!b509056|farming!b120273"
);
const CLIENT_SECRET =
  "01eb8aca-0a93-48c5-9d80-5ad07c064e12$V_ujMHvqA2sFdj1ECUcxb7UCy_RWCDe-aJuK0FK9WyE=";

// export async function useOAuth2(): Promise<object | undefined> {
//   const data = await fetch(`${AUTH_BASE_URL}/oauth/token`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//       Authorization: "Basic " + btoa(`${CLIENT_ID}:${CLIENT_SECRET}`),
//     },
//     body: new URLSearchParams({
//       grant_type: "password",
//       username: "sia.admin@msg-global.com",
//       password: "mj;mFBr+1",
//       login_hint: JSON.stringify({ origin: "sap.custom" }),
//     }),
//   });

//   const response = await data.json();

//   return response;
// }

/**
 * Function is used to get Bearer token. It will open popup with all necessary queries to get autorize code which will leater be used to get Bearer token. After successful or unsuccessful fetch, popup should close and user should be redirected to homepage.
 * @returns { Promise<object | undefined> } Bearer token and additional data related to it.
 * @todo Implement „reject” function of Promise. Currently, we just clear interval and close popup.
 */
export async function useOAuth2(): Promise<object | undefined> {
  // Here we use promise, because we dont know when user or will user be able to login. For that reason we use promise as this is an async operation.
  return new Promise((resolve, reject) => {
    // Opens a popup.
    const popup = openPopup(
      `${AUTH_BASE_URL}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}`
    );

    // Due to certain limitations, we need to use setInterval to periodically check if we were redirected back to a „localhost”.
    let intervalId = setInterval(async () => {
      try {
        // popup?.addEventListener("beforeunload", () => {
        //   clearInterval(intervalId);
        // });

        // We will not be able to check location of a cross-origin domain. Insted of returning error, we have implemented simple check.
        if (
          popup?.window.location?.hostname !== "localhost" &&
          popup?.window.location?.hostname !== "farmersapp-taupe.vercel.app"
        )
          return null;

        // Once we are redirected to a „localhost”, we need to get autorization code.
        const payload = queryToObject(
          popup?.window.location.search.split("?")[1]
        );

        // If we have not got a code, execution getting a Bearer token will not be possible.
        if (!payload?.code) return null;

        // Once we got Bearer token, it is safe to resolve Promise.
        resolve(await getBearerToken(payload.code));

        // We than close a popup.
        popup!.window.close();
        // We also need to clear the interval.
        clearInterval(intervalId);
      } catch (error) {
        console.log(error);

        // In case of error, close popup.
        // popup!.window.close();
        // Also, clear the interval.
        // clearInterval(intervalId);
      }
    }, 500);
  });
}

function openPopup(url: string) {
  const POPUP_HEIGHT = 700;
  const POPUP_WIDTH = 600;

  const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;

  return window.open(
    url,
    "",
    `top=${top}, left=${left}, width=${POPUP_WIDTH}, height=${POPUP_HEIGHT}`
  );
}

async function getBearerToken(code: string) {
  const data = await fetch(
    `${AUTH_BASE_URL}/oauth/token?grant_type=authorization_code&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&redirect_uri=${REDIRECT_URL}&code=${code}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  const response = await data.json();

  return response;
}

const queryToObject = (query: string) => {
  const parameters = new URLSearchParams(query);
  return Object.fromEntries(parameters.entries());
};
