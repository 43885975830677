import copyIcon from "../../assets/icons/copy.svg";
import pinIcon from "../../assets/icons/pin.svg";
import { useState } from "react";
import tickIcon from "../../assets/icons/copiedTick.svg";

interface MonthlyEventProps {
  readonly id: number;
  readonly title: string;
  readonly start: Date;
  readonly end: Date;
  readonly priority?: string;
  readonly assignee?: string;
  readonly location?: string;
  readonly status?: string;
  readonly description?: string;
  readonly onEdit?: (taskData: any) => void;
}

export default function MonthlyEvent({
  id,
  title,
  start,
  end,
  priority,
  assignee,
  location,
  status,
  description,
  onEdit,
}: MonthlyEventProps) {
  const [copied, setCopied] = useState(false);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const days = ["Sun", "Mon", "Tues", "Wed", "Thrus", "Fri", "Sat"];

  const handleEditClick = () => {
    if (onEdit) {
      onEdit({
        id,
        title,
        start,
        end,
        priority,
        assignee,
        location,
        status,
        description,
      });
    }
  };

  const handleCopyClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const taskDetails = `
Task Details:
ID: ${id}
Title: ${title}
Start: ${start.toLocaleString()}
End: ${end.toLocaleString()}
Priority: ${priority ?? "N/A"}
Assignee: ${assignee ?? "N/A"}
Location: ${location ?? "N/A"}
Status: ${status ?? "N/A"}
Description: ${description ?? "N/A"}
    `;
    try {
      await navigator.clipboard.writeText(taskDetails);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (error) {
      console.error("Failed to copy task details:", error);
    }
  };

  const isSameDay = start.toDateString() === end.toDateString();

  const getPriorityColor = (priority: string) => {
    switch (priority.toLowerCase()) {
      case "very high":
        return "var(--priority-very-high)";
      case "high":
        return "var(--priority-high)";
      case "medium":
        return "var(--priority-medium)";
      case "normal":
        return "var(--priority-normal)";
      case "low":
        return "var(--priority-low)";
      default:
        return "var(--priority-default)";
    }
  };

  return (
    <div
      className="rbc-monthly-event rbc-event-wrapper"
      onClick={handleEditClick}
    >
      <div
        className="rbc-monthly-event__content"
        style={{
          borderTop: `0.3rem solid ${getPriorityColor(priority || "")}`,
        }}
      >
        <div className="rbc-monthly-event__content__inner">
          <div className="rbc-monthly-event__content__duration">
            {start.getHours().toString().padStart(2, "0")}:
            {start.getMinutes().toString().padStart(2, "0")}{" "}
            <span className="rbc-monthly-event__content__duration__devider">
              |
            </span>
            {Math.abs(start.getTime() - end.getTime()) / (1000 * 60 * 60)} hrs
          </div>
          <h2 className="rbc-monthly-event__content__title">{title}</h2>
          {location && (
            <div className="rbc-monthly-event__content__location">
              <img src={pinIcon} alt="Location" /> {location}
            </div>
          )}
          <div className="rbc-monthly-event__content__date">
            {isSameDay
              ? `${days[start.getDay()]} ${start.getDate()} ${
                  months[start.getMonth()]
                }`
              : `${days[start.getDay()]} ${start.getDate()} ${
                  months[start.getMonth()]
                } - ${days[end.getDay()]} ${end.getDate()} ${
                  months[end.getMonth()]
                }`}
          </div>
          <div className="rbc-monthly-event__content__actions">
            <button
              className="rbc-monthly-event__content__actions__button"
              onClick={handleCopyClick}
            >
              {copied ? (
                <img
                  src={tickIcon}
                  alt="Copied"
                  className="rbc-monthly-event__content__actions__image"
                />
              ) : (
                <img src={copyIcon} alt="Copy task" />
              )}
            </button>
          </div>
          <div className="rbc-monthly-event__content__info">
            <span
              className="rbc-monthly-event__content__info__priority"
              style={{
                backgroundColor: getPriorityColor(priority || ""),
              }}
            >
              Priority: {priority}
            </span>
            {assignee && (
              <span className="rbc-monthly-event__content__info__assignee">
                {assignee.split(" ")[0].charAt(0)}{" "}
                {assignee.split(" ")[1]?.charAt(0)}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
