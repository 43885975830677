import React, {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import SectionWrapper from "./components/section/SectionWrapper";
import SectionHeading from "./components/section/SectionHeading";
import Weather from "./components/weather/Weather";
import { TaskList } from "./components/list/TaskList";
import Map from "./components/map/Map";
import LocationLabel from "./components/location/LocationLabel";
import Slider from "./components/slider/Slider";
import FieldCardSm from "./components/card/FieldCardSm";
import Heading from "./components/page/Heading";
import Modal from "./components/modal/Modal";
import NewTaskForm from "./components/form/NewTaskForm";
import Pagination from "./components/pagination/Pagination";
import { useFetch } from "./hooks/useFetch";
import { ThemeContext } from "./useContext/context";
import AddNewField from "./components/setupFarm/AddNewField";
import FilterComponent from "./components/FilterComponent";
import { FormButton } from "./components/form/Form";
import { Link } from "react-router-dom";
import SkeletonLoading from "./components/other/SkeletonLoading";
import moment from "moment";

import { Task as TaskInterface } from "./interfaces/task";
import { Field as FieldInterface } from "./interfaces/field";

import styles from "./styles/routes/home.module.scss";

import homePageIcon from "./assets/icons/home.svg";
import successIcon from "./assets/icons/successful.svg";
import filesicon from "./assets/icons/field.svg";

const startIndex = 0;
const endIndex = 3;

const FirstColContent = memo(function FirstColContent({
  tasks,
  setIsTasksModalOpen,
}: {
  tasks: TaskInterface[];
  setIsTasksModalOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const [filters, setFilters] = useState<string[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [tempFilters, setTempFilters] = useState<string[]>(filters);

  const handleFilterChange = (newFilters: string[]) => {
    setTempFilters(newFilters);
  };

  const handleApplyFilters = () => {
    setFilters(tempFilters);
    setIsFilterModalOpen(false);
  };

  const [currentTime, setCurrentTime] = useState(moment());

  useLayoutEffect(() => {
    setCurrentTime(moment());
  }, []);

  return (
    <>
      <SectionWrapper
        customClassName={styles.main__weather__section}
        customStyle={{
          border: 0,
        }}
      >
        <Weather />
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeading
          title="Tasks To Do"
          filterAction={() => setIsFilterModalOpen(true)}
          action={() => setIsTasksModalOpen!(true)}
          filterCount={filters.length}
        />
        <p
          style={{
            fontSize: "1.8rem",
            color: "#999999",
            fontWeight: "400",
          }}
        >
          TODAY - {currentTime.format("dddd, MMMM D")}{" "}
        </p>
        <TaskList tasks={tasks.slice(0, 5)} filter={filters} />
      </SectionWrapper>

      <Modal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        headingTitle="Filter Tasks"
        actionButtonTitle="Apply"
        actionButtonFn={handleApplyFilters}
      >
        <FilterComponent
          options={[
            "DRAFT",
            "OPEN",
            "IN_PROGRESS",
            "COMPLETED",
            "OVERDUE",
            "CANCELED",
          ]}
          selectedFilters={tempFilters}
          onFilterChange={handleFilterChange}
          onClearFilters={() => setTempFilters([])}
        />
      </Modal>
    </>
  );
});

const SecondColContent = memo(function SecondColContent({
  fields,
  setIsMyFieldsModalOpen,
}: {
  fields: FieldInterface[];
  setIsMyFieldsModalOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <SectionWrapper customClassName={styles.main__fields__section}>
        <SectionHeading
          title="My Fields"
          action={() => setIsMyFieldsModalOpen!(true)}
        />
        <div className={styles.main__fields}>
          <Slider customClassName={styles.main__fields__slider}>
            {fields.slice(startIndex, endIndex).map((field) => (
              <Link
                to="/field-details/field-tasks"
                key={field.ID}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <div
                  key={field.ID}
                  className={`${styles.main__fields__field} ${styles.field}`}
                >
                  <div className={styles.field__media}>
                    <Map
                      area={
                        field.boundary_geojson
                          ? JSON.parse(field.boundary_geojson)?.coordinates
                          : null
                      }
                    />
                  </div>
                  <div className={styles.field__heading}>
                    <span className={styles.field__title}>{field.name}</span>
                    <span className={styles.field__subtitle}>
                      {field.areaSize
                        ? `${parseFloat(field.areaSize).toFixed(2)} ${
                            field.areaSizeUnitSymbol
                          }`
                        : "/"}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
            {!fields.length && (
              <SkeletonLoading repetitions={3} height="20.5rem" gap="0" />
            )}
          </Slider>
        </div>
      </SectionWrapper>
      <SectionWrapper
        customClassName={styles.main__map__section}
        customStyle={{ flex: 1, padding: 0, border: 0 }}
      >
        <Map
          markers={[
            [45.10945030797108, 19.884384990622966],
            [46.10945030797108, 19.884384990622966],
          ]}
          zoomControl={true}
        />
        <LocationLabel
          locationName="Giri Purno"
          customStyle={{
            position: "absolute",
            top: "1.5rem",
            left: "2rem",
          }}
        />
      </SectionWrapper>
    </>
  );
});

function App() {
  const context = useContext(ThemeContext);

  const [tasks, setTasks] = useState<TaskInterface[]>([]);
  const [fields, setFields] = useState<FieldInterface[]>([]);
  const [newTask, setNewTask] = useState<FieldInterface[]>([]);
  const [filedMap, setFieldMap] = useState({});

  const [isMobile, setIsMobile] = useState(false);

  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isMyFieldsModalOpen, setIsMyFieldsModalOpen] = useState(false);
  const [isNewFieldModalOpen, setIsNewFieldModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("addFieldPage");
  const [paginationPage, setPaginationPage] = useState(1);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isSuccessCreateFieldModalOpen, setIsSuccessCreateFieldModalOpen] =
    useState<boolean>(false);
  const [newFieldName, setNewFieldName] = useState<string>("");
  const [newTaskName, setNewTaskName] = useState<string>("");

  const [fieldsPerPage, setFieldsPerPage] = useState(isMobile ? 6 : 9);

  const mobileModalStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    maxWidth: "107vw",
    height: "100%",
    maxHeight: "107vh",
    bottom: "0",
    borderRadius: 0,
    overflowY: "scroll",
    scrollbarWidth: "none",
    overflowX: "hidden",
    margin: 0,
    padding: "1.25rem 1.5625rem 1.25rem 1.5625rem",
  };

  const fieldsDisplayed = fields.slice(
    (paginationPage - 1) * fieldsPerPage,
    paginationPage * fieldsPerPage
  );

  const totalPages = Math.ceil(fields.length / fieldsPerPage);

  const handlePaginationChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setPaginationPage(page);
    }
  };

  const nextPage = () => {
    if (paginationPage < totalPages) {
      setPaginationPage(paginationPage + 1);
    }
  };

  const previousPage = () => {
    if (paginationPage > 1) {
      setPaginationPage(paginationPage - 1);
    }
  };

  const getBreakPoint = (variable: string) => {
    return parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(variable),
      10
    );
  };

  const observer = new ResizeObserver(() => {
    const breakpoint = getBreakPoint("--breakpoint-sm");
    if (window.innerWidth <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  const handlePageChange = ({
    page,
    isForward,
  }: {
    page?: string;
    isForward?: boolean;
  }) => {
    if (isForward) setCurrentPage(page || "nextPage");
    else setCurrentPage(page || "previousPage");
  };

  const handleNewFieldClick = () => {
    setIsMyFieldsModalOpen(false);
    setIsNewFieldModalOpen(true);
  };

  const handleTaskFormClose = () => {
    setIsNewTaskModalOpen(false);
  };

  const handleSuccessOpen = (taskName: string) => {
    setIsSuccessModalOpen(true);
    setNewTaskName(taskName);
  };

  const handleSuccessCreateFieldOpen = (fieldName: string) => {
    setIsSuccessCreateFieldModalOpen(true);
    setIsNewFieldModalOpen(false);
    setNewFieldName(fieldName);
  };

  useEffect(() => {
    observer.observe(document.body);

    (async function () {
      const taskList = useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/Tasks",
      });

      // const all = await useFetch({
      //   path: "/operations-api/v1/odata/v1/TaskService",
      // });

      // console.log(all)

      const fieldList = useFetch({
        path: "/odata/v2/AreaService/Areas",
      });

      const createTask = await useFetch({
        path: "/operations-api/v1/odata/v1/TaskService/Priorities",
      });

      const [taskList1, fieldList2, createTask1] = await Promise.all([
        taskList,
        fieldList,
        createTask,
      ]);

      // setFieldMap(
      //   fieldList2.value.map((field: FieldInterface) => {
      //     return { [field.ID]: field.name };
      //   })
      // );

      setTasks(taskList1.value.reverse());
      setFields(fieldList2.value);
      setNewTask(createTask1.value)
      
    })();
  }, []);

  useEffect(() => {
    setFieldsPerPage(isMobile ? 6 : 9);
  }, [isMobile]);

  return (
    <>
      <main className={styles.main}>
        <Heading
          pageTitle="Home"
          pageIcon={homePageIcon}
          actionTitle="New Task"
          action={() => setIsNewTaskModalOpen(true)}
        />
        {isMobile ? (
          <>
            <FirstColContent
              tasks={tasks}
              setIsTasksModalOpen={() => setIsTasksModalOpen(true)}
            />
            <SecondColContent
              fields={fields}
              setIsMyFieldsModalOpen={setIsMyFieldsModalOpen}
            />
          </>
        ) : (
          <>
            <div className={styles["main__first-col"]}>
              <FirstColContent
                tasks={tasks}
                setIsTasksModalOpen={setIsTasksModalOpen}
              />
            </div>
            <div className={styles["main__second-col"]}>
              <SecondColContent
                fields={fields}
                setIsMyFieldsModalOpen={setIsMyFieldsModalOpen}
              />
            </div>
          </>
        )}
      </main>

      <Modal
        headingTitle="My Fields"
        headingSubtitle="Here is a complete list of your Fields"
        actionButtonTitle="New Field"
        actionButtonFn={handleNewFieldClick}
        isOpen={isMyFieldsModalOpen}
        onClose={() => setIsMyFieldsModalOpen(false)}
        customStyle={isMobile ? mobileModalStyle : undefined}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: "1 1 auto",
              overflowY: "auto",
              overflowX: "hidden",
              padding: isMobile ? "1rem" : "0",
              marginTop: isMobile ? "1rem" : "0",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: isMobile
                  ? "repeat(2, 1fr)"
                  : "repeat(3,1fr)",
                gap: isMobile ? "1.5rem" : "1.5rem",
              }}
            >
              {fieldsDisplayed.map((field) => (
                <Link
                  to="/field-details/field-tasks"
                  key={field.ID}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <FieldCardSm
                    key={field.ID}
                    label={field.name}
                    subtitle={
                      field.areaSize
                        ? `${parseFloat(field.areaSize).toFixed(2)} ${
                            field.areaSizeUnitSymbol
                          }`
                        : "/"
                    }
                    area={
                      field.boundary_geojson
                        ? JSON.parse(field.boundary_geojson)?.coordinates
                        : null
                    }
                    // markers={field.markers}
                  />
                </Link>
              ))}
            </div>
          </div>

          <div
            style={{
              flex: "0 0 auto",
              padding: isMobile ? " 1rem " : " 0 ",
            }}
          >
            {fields.length > fieldsPerPage && (
              <Pagination
                currentPage={paginationPage}
                totalPages={totalPages}
                onNext={nextPage}
                onPrevious={previousPage}
              />
            )}
          </div>
        </div>
      </Modal>

      <Modal
        headingTitle="Create New Field"
        headingIcon={filesicon}
        isOpen={isNewFieldModalOpen}
        onClose={() => {
          setIsNewFieldModalOpen(false);
        }}
        customStyle={isMobile ? mobileModalStyle : undefined}
        size={isMobile ? "md" : "lg"}
      >
        <AddNewField
          page={currentPage}
          changePage={handlePageChange}
          closeModal={() => {
            setIsNewFieldModalOpen(false);
            setIsMyFieldsModalOpen(false);
          }}
          onSuccess={handleSuccessCreateFieldOpen}
        />
      </Modal>

      <Modal
        headingTitle="All Tasks"
        isOpen={isTasksModalOpen}
        onClose={() => setIsTasksModalOpen(false)}
      >
        <TaskList tasks={tasks} showDate={true} />
      </Modal>

      <Modal
        headingTitle="Create New Task"
        headingIcon={homePageIcon}
        isOpen={isNewTaskModalOpen}
        onClose={() => setIsNewTaskModalOpen(false)}
      >
        <NewTaskForm
          tasks={tasks}
          onClose={handleTaskFormClose}
          onSuccess={handleSuccessOpen}
        />
      </Modal>

      <Modal
        headingTitle=""
        onClose={() => setIsSuccessModalOpen(false)}
        showCloseButton={false}
        isOpen={isSuccessModalOpen}
        size="md"
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>Task Added!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created a New task <span>{newTaskName}</span>
            <br />
            It will now appear in your Task list.
          </p>
          <FormButton action={() => setIsSuccessModalOpen(false)}>
            Got it!
          </FormButton>
        </div>
      </Modal>

      <Modal
        headingTitle=""
        isOpen={isSuccessCreateFieldModalOpen}
        showCloseButton={false}
        onClose={() => {}}
        size="md"
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>New Field Created!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created a Field: <span>{newFieldName}</span>.
            It will now appear in your Field list.
          </p>
          <FormButton
            children="Got it!"
            action={() => setIsSuccessCreateFieldModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        headingTitle=""
        isOpen={isSuccessCreateFieldModalOpen}
        showCloseButton={false}
        onClose={() => {}}
        size="md"
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>New Field Created!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created a Field: <span>{newFieldName}</span>.
            It will now appear in your Field list.
          </p>
          <FormButton
            children="Got it!"
            action={() => setIsSuccessCreateFieldModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        headingTitle=""
        isOpen={isSuccessCreateFieldModalOpen}
        showCloseButton={false}
        onClose={() => {}}
        size="md"
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>New Field Created!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created a Field: <span>{newFieldName}</span>.
            It will now appear in your Field list.
          </p>
          <FormButton
            children="Got it!"
            action={() => setIsSuccessCreateFieldModalOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
}

export default App;
