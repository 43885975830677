export const validateFields = (formData: object) => {
  let isValid = true;
  let errorMessage = "";
  let invalidFieldsArray: string[] = [];

  Object.entries(formData).forEach(([key, value]) => {
    if (!value || value === "please select") {
      isValid = false;
      errorMessage = "Please populate the mandatory fields";
      invalidFieldsArray.push(key);
    }
  });

  return { isValid, errorMessage, invalidFieldsArray };
};

interface Dates {
  start: string;
  end: string;
  startField: string;
  endField: string;
}

export const validateDates = (dates: Dates) => {
  const { start, end, startField, endField } = dates;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(end);
  endDate.setHours(0, 0, 0, 0);

  let errorMessage = "";
  const invalidFieldsArray: string[] = [];

  if (startDate < today) {
    errorMessage = "The start date should be today or a future date";
    invalidFieldsArray.push(startField);
  }

  if (endDate < today || endDate < startDate) {
    errorMessage =
      "The end date must be today or a future date and cannot be earlier than the start date.";
    invalidFieldsArray.push(endField);
  }

  return {
    isValid: invalidFieldsArray.length === 0,
    errorMessage,
    invalidFieldsArray,
  };
};
