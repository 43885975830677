import styles from "../../styles/FarmSetupHeading.module.scss";
import arrLeft from "../../assets/icons/arrLeft.svg";
import { useContext, useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

interface TFarmInfoProps {
  page: string;
  changePage: ({
    page,
    isForward,
    goToIndex,
  }: {
    page?: string;
    isForward?: boolean;
    goToIndex?: string;
  }) => void;
  headingTitle?: string;
  headingSubtitle?: string;
}

const SetupHeading: React.FC<TFarmInfoProps> = ({
  page,
  changePage,
  headingTitle,
  headingSubtitle,
}) => {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  return (
    <div className={styles["setup-heading"]}>
      <img
        src={arrLeft}
        alt="Arr Left"
        onClick={() => changePage({ page, isForward: false })}
      />
      <span>
        <h2 className={styles["setup-heading__heading"]}>
          {headingTitle}
        </h2>
        <p className={styles["setup-heading__description"]}>
          {headingSubtitle}
        </p>
      </span>
    </div>
  );
};

export default SetupHeading;
