import { useEffect, useState } from "react";
import PageHeading from "../components/page/Heading";
import SectionWrapper from "../components/section/SectionWrapper";
import SectionHeading from "../components/section/SectionHeading";
import CustomCalendar from "../components/calendar/CustomCalendar";

import pageIcon from "../assets/icons/files.svg";
import pencilIcon from "../assets/icons/pencil.svg";
import stopIcon from "../assets/icons/stop.svg";
import alertIcon from "../assets/icons/alert.svg";
import closeBtn from "../assets/icons/close.svg";
import drafts from "../assets/icons/drafts.svg";
import canceled from "../assets/icons/canceled.svg";
import overdue from "../assets/icons/overdue.svg";
import tasks from "../assets/icons/tasks.svg";
import warningIcon from "../assets/icons/warning.svg";

import styles from "../styles/routes/tasks.module.scss";
import Modal from "../components/modal/Modal";
import NewTaskForm from "../components/form/NewTaskForm";
import { initialTasks, TaskList } from "../components/list/TaskList";
import successIcon from "../assets/icons/successful.svg";
import useIsMobile from "../hooks/useIsMobile";
import { FormButton, FormTextarea } from "../components/form/Form";
import ManageTaskForm from "../components/form/ManageTaskForm";
import dangerIcon from "../assets/icons/redDanger.svg";
import FullScreenModal from "../components/modal/FullScreenModal";

interface TaskList {
  id: number;
  title: string;
  start: Date;
  end: Date;
  priority: string;
  resourcesId: number;
  assignee: string;
  status: string;
  description: string;
}

export default function Tasks() {
  const [isMobile, setIsMobile] = useState(false);
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isManageTaskModalOpen, setIsManageTaskModalOpen] = useState(false);
  const [currentTaskData, setCurrentTaskData] = useState<any | null>(null);
  const [isDoneModalOpen, setIsDoneModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [isTaskSectionVisible, setIssetTaskSectionVisible] =
    useState<boolean>(true);
  let scrollTimeout: NodeJS.Timeout | null = null;

  const handleScroll = () => {
    setIssetTaskSectionVisible(false);

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => {
      setIssetTaskSectionVisible(true);
    }, 300);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [task, setTask] = useState<TaskList[]>([
    {
      id: 1,
      title: "Irrigation Setup",
      start: new Date(new Date().setHours(new Date().getHours() + 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 10)),
      priority: "very high",
      resourcesId: 1,
      assignee: "John Doe",
      status: "In Progress",
      description:
        "Setting up the irrigation system for the newly planted crops.",
    },
    {
      id: 2,
      title: "Soil Testing",
      start: new Date(new Date().setHours(new Date().getHours())),
      end: new Date(new Date().setHours(new Date().getHours() + 10)),
      priority: "high",
      resourcesId: 2,
      assignee: "John Boo",
      status: "Draft",
      description: "Collecting soil samples and testing for nutrient levels.",
    },
    {
      id: 3,
      title: "Crop Inspection",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 20)),
      priority: "normal",
      resourcesId: 3,
      assignee: "John Deo",
      status: "Open",
      description: "Inspecting crops for signs of pests or diseases.",
    },
    {
      id: 4,
      title: "Fertilizer Application",
      start: new Date(new Date().setHours(new Date().getHours() + 124)),
      end: new Date(new Date().setHours(new Date().getHours() + 160)),
      priority: "medium",
      resourcesId: 1,
      assignee: "John Doe",
      status: "Completed",
      description: "Applying fertilizers to enhance crop growth and yield.",
    },
    {
      id: 5,
      title: "Harvest Preparation",
      start: new Date(new Date().setHours(new Date().getHours() + 55)),
      end: new Date(new Date().setHours(new Date().getHours() + 75)),
      priority: "high",
      resourcesId: 4,
      assignee: "Jenny White",
      status: "In Progress",
      description: "Preparing tools and equipment for the upcoming harvest.",
    },
    {
      id: 6,
      title: "Pest Control",
      start: new Date(new Date().setHours(new Date().getHours() + 200)),
      end: new Date(new Date().setHours(new Date().getHours() + 224)),
      priority: "high",
      resourcesId: 5,
      assignee: "Jake Davis",
      status: "Completed",
      description: "Using organic pesticides to control pest infestation.",
    },
    {
      id: 7,
      title: "Seed Procurement",
      start: new Date(new Date().setHours(new Date().getHours() + 350)),
      end: new Date(new Date().setHours(new Date().getHours() + 355)),
      priority: "normal",
      resourcesId: 5,
      assignee: "Jake Davis",
      status: "Canceled",
      description: "Procuring seeds for the next planting season.",
    },
  ]);

  const tasksLists = initialTasks;

  const getBreakPoint = (variable: string) => {
    return parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(variable),
      10
    );
  };

  const observer = new ResizeObserver(() => {
    const breakpoint = getBreakPoint("--breakpoint-sm");
    if (window.innerWidth <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  useEffect(() => {
    observer.observe(document.body);
  });

  const handleOpenManageTask = (taskData: any) => {
    setCurrentTaskData(taskData);
    if (isMobile) {
      setActiveSection("Manage Task");
    } else {
      setIsManageTaskModalOpen(true);
    }
  };

  const handleCloseManageTask = () => {
    setIsManageTaskModalOpen(false);
    setActiveSection(null);
    setCurrentTaskData(null);
  };

  const handleSaveTaskChanges = (updatedTask: any) => {
    setTask((prevTasks) =>
      prevTasks.map((task) =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );

    if (updatedTask.status === "Done") {
      setIsDoneModalOpen(true);
    } else if (updatedTask.status === "Cancel") {
      setIsCancelModalOpen(true);
    } else {
      setIsManageTaskModalOpen(false);
    }

    handleCloseManageTask();
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setActiveSection(null);
      setIsClosing(false);
    }, 300);
  };

  const handleCancelSubmit = () => {
    if (!reason.trim()) {
      setError(true);
    } else {
      setError(false);
      setIsCancelModalOpen(false);
    }
  };

  const draftCount = tasksLists.filter(
    (t: any) => t.status === "drafted"
  ).length;
  const canceledCount = tasksLists.filter(
    (t: any) => t.status === "canceled"
  ).length;
  const overdueCount = tasksLists.filter(
    (t: any) => t.status === "overdue"
  ).length;

  const renderFullScreenContent = () => {
    if (!activeSection) {
      return null;
    }

    let content = null;
    let isFullScreen = false;
    let icon = null;

    const sectionIcons: Record<string, string> = {
      Drafts: drafts,
      "Canceled Tasks": canceled,
      "Overdue Tasks": overdue,
      "New Task": tasks,
    };

    switch (activeSection) {
      case "Drafts":
        content = (
          <TaskList
            tasks={[]}
            filter={["drafted"]}
            showDate={true}
            showCheckbox={false}
            showStatusBar={false}
          />
        );
        isFullScreen = false;
        icon = sectionIcons["Drafts"];
        break;
      case "Canceled Tasks":
        content = (
          <TaskList
            tasks={[]}
            filter={["canceled"]}
            showDate={true}
            showCheckbox={false}
            showStatusBar={false}
          />
        );
        isFullScreen = false;
        icon = sectionIcons["Canceled Tasks"];
        break;
      case "Overdue Tasks":
        content = (
          <TaskList
            tasks={[]}
            filter={["overdue"]}
            showDate={true}
            showCheckbox={false}
            showStatusBar={false}
          />
        );
        isFullScreen = false;
        icon = sectionIcons["Overdue Tasks"];
        break;
      case "New Task":
        content = (
          <NewTaskForm
            onSuccess={() => {
              setActiveSection(null);
              setIsSuccessModalOpen(true);
            }}
            onClose={handleClose}
          />
        );
        isFullScreen = true;
        icon = sectionIcons["New Task"];
        break;
      case "Manage Task":
        content = (
          <ManageTaskForm
            taskData={currentTaskData}
            onSave={handleSaveTaskChanges}
            onClose={handleCloseManageTask}
            onDone={() => {
              setIsDoneModalOpen(true);
            }}
            onCancel={() => {
              setIsCancelModalOpen(true);
            }}
          />
        );
        isFullScreen = true;
        icon = sectionIcons["New Task"];
        break;
      default:
        content = null;
    }

    return (
      <FullScreenModal
        title={activeSection || ""}
        icon={sectionIcons[activeSection || ""]}
        isOpen={!!activeSection}
        isFullScreen={["New Task", "Manage Task"].includes(activeSection || "")}
        onClose={handleClose}
        isClosing={isClosing}
      >
        {content}
      </FullScreenModal>
    );
  };

  return (
    <>
      <main className={styles.main}>
        <PageHeading
          pageTitle="Tasks"
          pageIcon={pageIcon}
          action={() => {
            if (isMobile) {
              setActiveSection("New Task");
            } else {
              setIsNewTaskModalOpen(true);
            }
          }}
          actionTitle="New Task"
        />
        <SectionWrapper
          customStyle={{
            gridColumn: "span 12",
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            alignItems: "stretch",
            padding: 0,
            height: "100%",
            border: 0,
          }}
        >
          <CustomCalendar tasks={task} onEditTask={handleOpenManageTask} />
        </SectionWrapper>

        <div
          style={{
            gridColumn: "1 / -1",
            display: isMobile ? "flex" : "grid",
            flexDirection: isMobile ? "row" : undefined,
            overflowX: isMobile ? "auto" : undefined,
            gridTemplateColumns: !isMobile
              ? "repeat(auto-fit, minmax(35rem, 1fr))"
              : undefined,
            gap: "var(--spacing-xs)",
          }}
          className={
            isMobile
              ? `${styles.taskSections} ${
                  isTaskSectionVisible ? styles.visible : styles.hidden
                }`
              : ""
          }
        >
          <SectionWrapper>
            <SectionHeading
              title="Drafts Tasks"
              label={draftCount.toString()}
              icon={pencilIcon}
              iconBackground="transparent"
              customClassName={styles["task-section"]}
              showActionButton={!isMobile}
              action={() => {
                if (isMobile) setActiveSection("Drafts");
                else {
                  setModalTitle("Drafts Tasks");
                  setIsTasksModalOpen(true);
                }
              }}
            />
            {!isMobile ? (
              <TaskList
                tasks={[]}
                filter={["drafted"]}
                showDate={true}
                showCheckbox={false}
              />
            ) : (
              ""
            )}
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeading
              title="Canceled Tasks"
              label={canceledCount.toString()}
              icon={stopIcon}
              iconBackground="transparent"
              customClassName={styles["task-section"]}
              showActionButton={!isMobile}
              action={() => {
                if (isMobile) setActiveSection("Canceled Tasks");
                else {
                  setModalTitle("Canceled Tasks");
                  setIsTasksModalOpen(true);
                }
              }}
            />
            {!isMobile ? (
              <TaskList
                tasks={[]}
                filter={["canceled"]}
                showDate={true}
                showCheckbox={false}
              />
            ) : (
              ""
            )}
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeading
              title="Overdue Tasks"
              label={overdueCount.toString()}
              icon={alertIcon}
              iconBackground="transparent"
              customClassName={styles["task-section"]}
              showActionButton={!isMobile}
              action={() => {
                if (isMobile) setActiveSection("Overdue Tasks");
                else {
                  setModalTitle("Overdue Tasks");
                  setIsTasksModalOpen(true);
                }
              }}
            />
            {!isMobile ? (
              <TaskList
                tasks={[]}
                filter={["overdue"]}
                showDate={true}
                showCheckbox={false}
              />
            ) : (
              ""
            )}
          </SectionWrapper>
        </div>
        {renderFullScreenContent()}
      </main>
      <Modal
        headingTitle="Create New Task"
        headingIcon={pageIcon}
        isOpen={isNewTaskModalOpen}
        onClose={() => setIsNewTaskModalOpen(false)}
      >
        <NewTaskForm 
          onSuccess={() => {
            setIsNewTaskModalOpen(false);
            setIsSuccessModalOpen(true);
          }}
          onClose={() => setIsNewTaskModalOpen(false)}
        />
      </Modal>
      <Modal
        headingTitle={modalTitle}
        customClassName={styles["tasks-modal"]}
        headingIcon={
          modalTitle === "Drafts"
            ? drafts
            : modalTitle === "Canceled Tasks"
            ? canceled
            : modalTitle === "Overdue Tasks"
            ? overdue
            : tasks
        }
        isOpen={isTasksModalOpen}
        onClose={() => setIsTasksModalOpen(false)}
      >
        <TaskList
          tasks={[]}
          filter={
            modalTitle === "Drafts Tasks"
              ? ["drafted"]
              : modalTitle === "Canceled Tasks"
              ? ["canceled"]
              : ["overdue"]
          }
          showDate={true}
          showCheckbox={false}
          showStatusBar={false}
        />
      </Modal>

      <Modal
        headingTitle=""
        onClose={() => setIsSuccessModalOpen(false)}
        showCloseButton={false}
        isOpen={isSuccessModalOpen}
        size="md"
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>Task Added!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created a New task. <br />
            It will now appear in your Task list.
          </p>
          <FormButton action={() => setIsSuccessModalOpen(false)}>
            Got it!
          </FormButton>
        </div>
      </Modal>

      <Modal
        headingTitle="Manage Task"
        isOpen={isManageTaskModalOpen}
        onClose={handleCloseManageTask}
        headingIcon={tasks}
      >
        <ManageTaskForm
          taskData={currentTaskData}
          onClose={handleCloseManageTask}
          onSave={handleSaveTaskChanges}
          onDone={() => {
            setIsDoneModalOpen(true);
          }}
          onCancel={() => {
            setIsCancelModalOpen(true);
          }}
        />
      </Modal>

      <Modal
        headingTitle=""
        isOpen={isDoneModalOpen}
        onClose={() => setIsDoneModalOpen(false)}
        size="md"
        showCloseButton={false}
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>Task Completed!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully Completed the task.
          </p>
          <FormButton
            children="Got it!"
            action={() => setIsDoneModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        headingTitle="Cancel Task"
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        headingIcon={warningIcon}
        size="md"
        customClassName={styles["cancel-modal__container"]}
      >
        <div className={styles["cancel-modal"]}>
          {error && (
            <div className={styles["cancel-modal__error-container"]}>
              <p
                className={
                  styles["cancel-modal__error-container__error-message"]
                }
              >
                <img src={dangerIcon} alt="" />
                Please provide cancellation reason
              </p>
            </div>
          )}
          <div className={styles["cancel-modal__content"]}>
            <p>Please provide cancellation reason (1000 char max)*</p>
            <FormTextarea
              id="cancel-reason"
              name="reason"
              value={reason}
              isRequired
              onChange={(e) => setReason(e.target.value)}
              customClassName={styles["cancel-modal__content__textarea"]}
            />
          </div>
          <FormButton
            customCSS={{ marginTop: "1rem" }}
            action={handleCancelSubmit}
          >
            Submit
          </FormButton>
        </div>
      </Modal>
    </>
  );
}
