import React, { useState, useEffect } from "react";
import styles from "./DeleteCropZone.module.scss";
import taskAssignedIcon from "../../assets/icons/taskAssigned.svg";
import { FormButton } from "../form/Form";

interface DeleteCropZoneSuccessProps {
  onGotIt: () => void;
}

const CropZoneSuccess: React.FC<DeleteCropZoneSuccessProps> = ({ onGotIt }) => {
  useEffect(() => {
    const cropZoneData = localStorage.getItem("cropZoneData");
    if (cropZoneData !== null) {
      setCropZone(JSON.parse(cropZoneData));
    }
  }, []);

  const [cropZone, setCropZone] = useState({
    cropZoneName: "",
    cropVariety: "",
    crop: "",
    cropPurpose: "",
    cropSeason: "",
    cropPerennial: "",
    seasonStart: "",
    seasonEnd: "",
    productionType: "",
    area: "",
  });
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.checkIcon}>
          <img src={taskAssignedIcon} alt="Task Assigned Icon" />
        </div>
      </div>
      <p className={styles.subheader}>
        You have deleted your Crop Zone:
        <br />
        <p className={styles.subtext}>{cropZone.cropZoneName}</p>
      </p>
      <FormButton customCSS={{ marginTop: "1rem" }} action={onGotIt}>
        Got It!
      </FormButton>
    </div>
  );
};

export default CropZoneSuccess;
