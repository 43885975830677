import { useState, useEffect } from "react";
import SectionContentGroup from "../components/section/SectionContentGroup";
import {
  Form,
  FormButton,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
} from "../components/form/Form";

import editIcon from "../assets/icons/edits.svg";
import trashIcon from "../assets/icons/trash.svg";

import Modal from "../components/modal/Modal";
import DeleteCropZone from "../components/field/DeleteCropZone";

import styles from "../styles/routes/fieldDetailsInformation.module.scss";
import arrowRightIcon from "../assets/icons/arrRight.svg";

const buttonWithIconCSS = {
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "auto",
  width: "3.9rem",
  height: "3.9rem",
  backgroundColor: "transparent",
  border: "1px solid var(--bd-clr-primary)",
  borderRadius: "0.8rem",
  cursor: "pointer",
};

interface CropZone {
  cropZoneName: string;
  cropVariety: string;
  crop: string;
  cropPurpose: string;
  cropSeason: string;
  cropPerennial: string;
  seasonStart: string;
  seasonEnd: string;
  productionType: string;
  area: string;
}

export default function FieldDetailsInformation() {
  const [cropZoneEditModal, setCropZoneEditModal] = useState<boolean>(false);
  const [deleteCropZoneModal, setDeleteCropZoneModal] =
    useState<boolean>(false);
  const [cropZone, setCropZone] = useState<CropZone>({
    cropZoneName: "",
    cropVariety: "",
    crop: "",
    cropPurpose: "",
    cropSeason: "",
    cropPerennial: "",
    seasonStart: "",
    seasonEnd: "",
    productionType: "",
    area: "",
  });

  useEffect(() => {
    const cropZoneData = localStorage.getItem("cropZoneData");
    if (cropZoneData !== null) {
      setCropZone(JSON.parse(cropZoneData));
    }
  }, []);

  const handleCropZoneEdit = () => {
    setCropZoneEditModal(true);
  };

  const handleDeleteCropZone = () => {
    setDeleteCropZoneModal(true);
  };

  const handleGotIt = () => {
    localStorage.removeItem("cropZoneData");
    setCropZone({
      cropZoneName: "",
      cropVariety: "",
      crop: "",
      cropPurpose: "",
      cropSeason: "",
      cropPerennial: "",
      seasonStart: "",
      seasonEnd: "",
      productionType: "",
      area: "",
    });
    setDeleteCropZoneModal(false);
  };

  const hasCropZoneDetails = (cropZone: CropZone): boolean => {
    return Boolean(
      cropZone.cropZoneName ||
        cropZone.cropVariety ||
        cropZone.crop ||
        cropZone.cropPurpose ||
        cropZone.cropSeason ||
        cropZone.cropPerennial ||
        cropZone.seasonStart ||
        cropZone.seasonEnd ||
        cropZone.productionType ||
        cropZone.area
    );
  };

  return (
    <>
      <SectionContentGroup
        title="Field Details"
        actions={
          <>
            <FormButton
              type="button"
              customCSS={{
                margin: 0,
                padding: "0.8rem 3rem",
              }}
            >
              Save
            </FormButton>
          </>
        }
      >
        <Form>
          <FormGroup isRow={true}>
            <FormInput id="field-name" name="field-name" label="FIELD NAME*" />
            <FormInput id="field-area" name="field-area" label="AREA*" />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormTextarea
              id="field-area-desctiption"
              name="field-area-desctiption"
              label="DESCRIPTION*"
            />
            <FormGroup isRow={true}>
              <FormInput
                id="start-date"
                name="start-date"
                type="date"
                label="Valid From *"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
                customClassName={styles.startDate}
              />
              <FormInput
                id="end-date"
                name="end-date"
                type="date"
                label="Valid to *"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
                customClassName={styles.endDate}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup isRow={true} customCSS={{ display: "flex", gap: "1rem" }}>
            <FormTextarea
              id="field-area-coordinates"
              name="field-area-coordinates"
              label="Coordinates/Polygon*"
              placeholder="[80.96632957690962, 26.777063336375846...]"
              customCSS={{ flex: 1 }}
            />
            <FormGroup isRow={true}>
              <FormInput id="field-size" name="field-size" label="SIZE (HA)*" />
              <FormSelect
                id="field-area-type"
                name="field-area-type"
                label="AREA TYPE*"
                selectOptions={[]}
              />
            </FormGroup>
          </FormGroup>
        </Form>
      </SectionContentGroup>
      {hasCropZoneDetails(cropZone) && (
        <SectionContentGroup
          customCSS={{ borderTop: "0.1rem solid #E4E7E6", paddingTop:"1.2rem" }}
          title="Crop Zone Details"
          actions={
            <>
              <button style={buttonWithIconCSS} onClick={handleCropZoneEdit}>
                <img src={editIcon} alt="Edit Crop Zone Details" />
              </button>
              <button style={buttonWithIconCSS} onClick={handleDeleteCropZone}>
                <img src={trashIcon} alt="Delete Crop Zone Details" />
              </button>
            </>
          }
        >
          <Form>
            <FormGroup isRow={true}>
              <FormInput
                id="crop-zone-crop"
                name="crop-zone-crop"
                label="CROP*"
                value={cropZone.cropZoneName}
              />
              <FormInput
                id="crop-zone-season"
                name="crop-zone-season"
                label="SEASON*"
                value={cropZone.cropSeason}
              />
            </FormGroup>
            <FormGroup isRow={true}>
              <FormInput
                id="crop-zone-variety"
                name="crop-zone-variety"
                label="VARIETY*"
                value={cropZone.cropVariety}
              />
              <FormInput
                id="crop-zone-production-purpose"
                name="crop-zone-production-purpose"
                label="PRODUCT PURPOSE*"
                value={cropZone.cropPurpose}
              />
            </FormGroup>
          </Form>
        </SectionContentGroup>
      )}
      <Modal
        headingTitle="Manage Crop Zone"
        headingSubtitle="Manage information of your Crop Zone"
        isOpen={cropZoneEditModal}
        onClose={() => setCropZoneEditModal(false)}
        headingIcon=""
      >
        <Form
          customCSS={{
            border: "1px solid var(--clr-lightgreen)",
            padding: "2rem",
            borderRadius: "var(--form-section-br)",
          }}
        >
          <FormGroup isRow={true}>
            <FormInput
              id="new-crop-zone-name"
              name="new-crop-zone-name"
              label="CROP ZONE NAME*"
              value={cropZone.cropZoneName}
              onChange={(e) =>
                setCropZone({ ...cropZone, cropZoneName: e.target.value })
              }
            />
            <FormSelect
              id="new-crop-zone-crop"
              name="new-crop-zone-crop"
              label="VARIETY*"
              value={cropZone.cropVariety}
              selectOptions={[]}
              onChange={(e) =>
                setCropZone({ ...cropZone, cropVariety: e.target.value })
              }
              isRequired={true}
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormInput
              id="new-crop-zone-season"
              name="new-crop-zone-season"
              label="CROP*"
              placeholder="Apple"
              isRequired={true}
              value={cropZone.crop}
              onChange={(e) =>
                setCropZone({ ...cropZone, crop: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormSelect
              id="new-crop-zone-area-type"
              name="new-crop-zone-area-type"
              label="CROP PURPOSE"
              value={cropZone.cropPurpose}
              selectOptions={[]}
              onChange={(e) =>
                setCropZone({ ...cropZone, cropPurpose: e.target.value })
              }
            />
            <FormSelect
              id="new-crop-zone-season-type"
              name="new-crop-zone-season-type"
              label="SEASON*"
              value={cropZone.cropSeason}
              selectOptions={[]}
              onChange={(e) =>
                setCropZone({ ...cropZone, cropSeason: e.target.value })
              }
              isRequired={true}
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormInput
              id="new-crop-zone-perennial"
              name="new-crop-zone-perennial"
              label="PERENNIAL"
              customCSS={{
                height: "50px",
              }}
              value={cropZone.cropPerennial}
              onChange={(e) =>
                setCropZone({ ...cropZone, cropPerennial: e.target.value })
              }
            />
            <FormGroup isRow={true}>
              <FormInput
                id="new-field-valid-from"
                name="new-field-valid-from"
                type="date"
                label="SEASON START*"
                isRequired={true}
                value={cropZone.seasonStart}
                onChange={(e) =>
                  setCropZone({ ...cropZone, seasonStart: e.target.value })
                }
                customCSS={{
                  flex: 1,
                }}
              />
              <FormInput
                id="new-field-valid-TO"
                name="new-field-valid-TO"
                type="date"
                label="SEASON END*"
                isRequired={true}
                value={cropZone.seasonEnd}
                onChange={(e) =>
                  setCropZone({ ...cropZone, seasonEnd: e.target.value })
                }
                customCSS={{
                  flex: 1,
                }}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup isRow={true}>
            <FormSelect
              id="new-crop-zone-season-type"
              name="new-crop-zone-season-type"
              label="PRODUCTION TYPE"
              value={cropZone.productionType}
              onChange={(e) =>
                setCropZone({ ...cropZone, productionType: e.target.value })
              }
              selectOptions={[]}
            />
            <FormInput
              id="new-field-size"
              name="new-field-size"
              label="AREA"
              placeholder="Arable"
              value={cropZone.area}
              onChange={(e) =>
                setCropZone({ ...cropZone, area: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormButton isTransparent={true} type="button" action={() => {}}>
              Cancel
            </FormButton>
            <FormButton
              action={() => {
                setCropZoneEditModal(false);
                localStorage.setItem("cropZoneData", JSON.stringify(cropZone));
              }}
            >
              Save
            </FormButton>
          </FormGroup>
        </Form>
      </Modal>
      <Modal
        headingTitle=""
        isOpen={deleteCropZoneModal}
        onClose={() => setDeleteCropZoneModal(false)}
        showCloseButton={false}
        size="md"
      >
        <DeleteCropZone onGotIt={handleGotIt} />
      </Modal>
    </>
  );
}
