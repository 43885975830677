import styles from "../../styles/FarmSetupFields.module.scss";
import SetupHeading from "./SetupHeading";
import SingleField from "./SingleField";
import type { SetupProps } from "../../types";
import { useEffect, useContext, useState } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";
import Modal from "../modal/Modal";
import alertIcon from "../../assets/icons/tasks/alertTask.svg";
import useIsMobile from "../../hooks/useIsMobile";

type TFarmInfoProps = SetupProps;

const FarmFields: React.FC<TFarmInfoProps> = ({ page, changePage }) => {
  const { setLocale } = useContext(LocaleContext);
  const [fields, setFields] = useState([
    { id: 1, name: "Auskeben Nord", isChecked: false },
    { id: 2, name: "Auskeben Süd", isChecked: false },
  ]);
  const [selectAll, setSelectAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const isMobile = useIsMobile();

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setFields(fields.map((field) => ({ ...field, isChecked: checked })));
  };

  const handleFieldChange = (id: number) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, isChecked: !field.isChecked };
      }
      return field;
    });
    setFields(newFields);
    setSelectAll(newFields.every((field) => field.isChecked));
  };

  const handleFieldDelete = (id: number) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  const handleImportClick = () => {
    if (fields.every((field) => !field.isChecked)) {
      setErrorMessage("Please select fields for import");
      setErrorModalOpen(true);
    } else {
      setErrorMessage("");
      changePage({ goToIndex: "4" });
    }
  };

  return (
    <div className={styles["setup-fields"]}>
      <SetupHeading
        page={page}
        changePage={changePage}
        headingTitle="Check Your Field Information"
        headingSubtitle="Select the fields you would like to import"
      />
      <div className={styles["setup-fields__actions"]}>
        <label className={styles["setup-fields__selectAll"]}>
          <input
            type="checkbox"
            className={styles["setup-fields__selectAllCheckbox"]}
            onChange={handleSelectAll}
            checked={selectAll}
          />
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" rx="4" />
            <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
          </svg>
          <span className={styles["setup-fields__selectAllText"]}>
            {selectAll ? <>DESELECT ALL</> : <>SELECT ALL</>}
          </span>
        </label>
        <button
          onClick={() => changePage({ goToIndex: "3" })}
          type="button"
          className={styles["setup-fields__addFieldBtn"]}
        >
          <Trans id="farmFields.addButton">Add Field</Trans>
        </button>
      </div>
      <div className={styles["setup-fields__formContainer"]}>
        <form action="" className={styles["setup-fields__form"]}>
          {fields.map((field) => (
            <SingleField
              key={field.id}
              field={field}
              onChange={() => handleFieldChange(field.id)}
              onDelete={handleFieldDelete}
            />
          ))}
        </form>
      </div>
      <button
        onClick={handleImportClick}
        className={styles["setup-fields__importButton"]}
      >
        {" "}
        Import Your Fields
      </button>
      <Modal
        size={isMobile ? "xs" : "md"}
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        actionButtonFn={() => setErrorModalOpen(false)}
        showCloseButton={true}
        customStyle={{
          overflowY: "hidden",
        }}
      >
        <div className={styles["farmFieldsModal"]}>
          <img src={alertIcon} alt="Alert" className={styles["farmFieldsModal__icon"]}/>
          <h2 className={styles["farmFieldsModal__heading"]}>{errorMessage}</h2>
        </div>
      </Modal>
    </div>
  );
};

export default FarmFields;
