import React, { useState, useEffect } from "react";
import RecommendationItem from "./RecommendationItem";
import styles from "./RecommendationItem.module.scss";
import { useFetch } from "../../hooks/useFetch";
import dropdownIcon from "../../assets/icons/tasks/dropdown.svg";
import closeupIcon from "../../assets/icons/tasks/closeup.svg";

type RecommendationStatus = "none" | "accepted" | "declined";

export default function RecommendationsList() {

  const [expanded, setExpanded] = useState<boolean>(false);
  const [recommendations, setRecommendations] = useState<any>([]);
  const [recommendationTasks, setRecommendationTasks] = useState()
  const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
  const [declineModalOpen, setDeclineModalOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<RecommendationStatus>("none");

  const toggleExpand = () => {
    setExpanded((prev: boolean) => !prev);
  };

  useEffect(() => {
    (async function () {
      const recommendations = await useFetch({
        path: "/foundation-api/v1/odata/v2/RecommendationService/Recommendations"
      })

      const recommendationsTasks = await useFetch({
        path: "/foundation-api/v1/odata/v2/RecommendationService/RecommendedTasks"
      })

      const [recommendation, tasks] = await Promise.all([
        recommendations,
        recommendationsTasks,
      ]);

      setRecommendations(recommendation.value)
      setRecommendationTasks(recommendation.value)

      

      // setReccomendations(recommendations.value.map((reccomendationDescription:RecommendationsInterface) => reccomendationDescription))
     // setRecommendations(recommendations.value)

      console.log(recommendations)
      console.log(recommendationsTasks)

    })()
  }, [])


  return (
    <div className={styles.recommendationsList}>
      {recommendations.map((recommendation: any, index: any) => (
        <div key={index}
          className={`${styles.recommendationItem} ${expanded ? styles.expanded : ""
            }`}
        >
          <div className={styles.recommendationItem__topRow}>
            <div className={styles.recommendationItem__info}>
              <span className={styles.recommendationItem__title}>Recommendation {recommendation.name}</span>
              <span className={styles.recommendationItem__due}>DUE {recommendation.dueDate}</span>
              <p>{recommendation.recommendedTasks}</p>
            </div>
            <div className={styles.recommendationItem__actions}>
              {status === "none" && (
                <>
                  <button
                    className={styles.acceptButton}
                    onClick={() => setAcceptModalOpen(true)}
                  >
                    ACCEPT
                  </button>
                  <button
                    className={styles.declineButton}
                    onClick={() => setDeclineModalOpen(true)}
                  >
                    DECLINE
                  </button>
                </>
              )}
              {status === "accepted" && (
                <span className={styles.acceptedText}>ACCEPTED</span>
              )}
              {status === "declined" && (
                <span className={styles.declinedText}>DECLINED</span>
              )}
              <button className={styles.dropdownButton} onClick={toggleExpand}>
                <img
                  src={expanded ? closeupIcon : dropdownIcon}
                  alt={expanded ? "Less options" : "More options"}
                />
              </button>
            </div>
          </div>
          {expanded && (
            <div className={styles.recommendationItem__content}>
              <hr className={styles.divider} />
              <div className={styles.taskColumns}>
                <div className={styles.taskColumn}>
                  <table className={styles.taskTable}>
                    <tbody>
                      <tr>
                        <th className={styles.taskLabel}>TASK NAME</th>
                        <td className={styles.taskValue}>Fertilization</td>
                        <th className={styles.taskLabel}>PRIORITY</th>
                        <td className={styles.taskValue}>Medium</td>
                      </tr>
                      <tr>
                        <th className={styles.taskLabel}>TASK TYPE</th>
                        <td className={styles.taskValue}>Fertilization</td>
                        <th className={styles.taskLabel}>Start/End Date</th>
                        <td className={styles.taskValue}>_</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={styles.taskDescription}>
                    <span className={styles.descriptionLabel}>DESCRIPTION</span>
                    <span className={styles.descriptionValue}>{recommendation.description}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}