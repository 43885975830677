import { ReactNode, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Heading from "./Heading";
import Map from "../map/Map";

import arrLeft from "../../assets/icons/arrLeft.svg";
import arrRight from "../../assets/icons/arrRight.svg";
import trashIcon from "../../assets/icons/trash.svg";
import redDanger from "../../assets/icons/warning.svg";
import susccessIcon from "../../assets/icons/successful.svg";

import styles from "./NestedLayout.module.scss";
import Modal from "../modal/Modal";
import { FormButton } from "../form/Form";

interface NavLinkType {
  icon?: string;
  label: string;
  link: string;
}

function Aside({ navLinks }: { navLinks: NavLinkType[] }) {
  const [isConformDeleteOpen, setIsConformDeleteOpen] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsConformDeleteOpen(false);
    setIsDeleteModalOpen(true);
  };

  return (
    <>
      <aside className={styles["aside"]}>
        <div className={styles["aside__heading"]}>
          <div className={styles["aside__heading__media"]}>
            <Map />
          </div>
        </div>
        <div className={styles["aside__nav"]}>
          <div className={styles["aside__nav__heading"]}>
            <h3 className={styles["aside__nav__heading__title"]}>FIELD</h3>
            <h4
              className={
                styles["aside__nav__heading__title"] +
                " " +
                styles["aside__nav__heading__title--main"]
              }
            >
              Grapes Field
            </h4>
            <button
              className={styles["aside__nav__heading__action"]}
              onClick={() => setIsConformDeleteOpen(true)}
            >
              <img src={trashIcon} alt="" />
            </button>
          </div>
          {navLinks.map((navLink, index) => (
            <NavLink
              key={navLink.label.replace(" ", "_") + index}
              className={({ isActive, isPending }) =>
                (isActive
                  ? styles["aside__nav__link--is-active"]
                  : isPending
                  ? styles["aside__nav__link--is-pending"]
                  : "") +
                " " +
                styles["aside__nav__link"]
              }
              to={navLink.link}
            >
              {navLink.icon && <img src={navLink.icon} alt={navLink.label} />}
              {navLink.label}
            </NavLink>
          ))}
        </div>
      </aside>
      <Modal
        headingTitle=""
        showCloseButton={false}
        onClose={() => setIsConformDeleteOpen(false)}
        isOpen={isConformDeleteOpen}
        size="md"
      >
        <div className={styles["modal__content"]}>
          <img src={redDanger} alt="" />
          <h2 className={styles["modal__content__title"]}>
            Are you sure you want to delete the field?
          </h2>
          <p className={styles["modal__content__message"]}>
            This action cannot be undone.
          </p>
          <div className={styles["modal__content__actions"]}>
            <FormButton isTransparent={true} action={()=>setIsConformDeleteOpen(false)}>No</FormButton>
            <FormButton customCSS={{height:"100%"}} action={handleClick}>Yes</FormButton>
          </div>
        </div>
      </Modal>
      <Modal
        headingTitle=""
        onClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
        size="md"
        showCloseButton={false}
      >
        <div className={styles["success-modal-content"]}>
          <img src={susccessIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>Field Deleted!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully deleted the Field.
          </p>
          <FormButton action={() => setIsDeleteModalOpen(false)}>
            Got it!
          </FormButton>
        </div>
      </Modal>
    </>
  );
}

function ContentHeading({ navLinks }: { navLinks: NavLinkType[] }) {
  const navigate = useNavigate();
  const location = useLocation();
  const strippedPath = location.pathname.replace("/field-details", "");

  let currentIndex = navLinks.findIndex((navLink) => {
    return strippedPath === `/${navLink.link}`;
  });

  if (currentIndex === -1) {
    currentIndex = 0;
  }

  const goTo = (n: number) => {
    const newIndex = currentIndex + n;
    if (newIndex >= 0 && newIndex < navLinks.length) {
      navigate("/field-details" + `/${navLinks[newIndex].link}`);
    }
  };

  return (
    <div className={styles["content__heading"]}>
      <button
        className={styles.nav__logo}
        type="button"
        onClick={() => goTo(-1)}
        disabled={currentIndex === 0}
      >
        <img src={arrLeft} alt="Previous" />
      </button>
      <h2 className={styles["content__heading__title"]}>
        {navLinks[currentIndex].label}
      </h2>
      <button
        className={styles.nav__logo}
        type="button"
        onClick={() => goTo(1)}
        disabled={currentIndex === navLinks.length - 1}
      >
        <img src={arrRight} alt="Next" />
      </button>
    </div>
  );
}

export default function NestedLayout({
  pageTitle,
  navLinks,
  actionTitle,
  action,
  children,
  optionalActionTitle,
  optionalAction,
  pageIcon,
}: {
  pageTitle: string;
  navLinks: NavLinkType[];
  actionTitle?: string;
  action?: () => void;
  children: ReactNode;
  optionalActionTitle?: string;
  optionalAction?: () => void;
  pageIcon?: string;
}) {
  return (
    <>
      <main className={styles.main}>
        <Heading
          pageTitle={pageTitle}
          actionTitle={actionTitle}
          pageIcon={pageIcon}
          action={action}
          optionalActionTitle={optionalActionTitle}
          optionalAction={optionalAction}
        />
        <Aside navLinks={navLinks} />
        <section className={styles.content}>
          <ContentHeading navLinks={navLinks} />
          {children}
        </section>
      </main>
    </>
  );
}
