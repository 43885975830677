import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import NestedLayout from "../components/page/NestedLayout";

import fieldInformationLinkIcon from "../assets/icons/field-information.svg";
import fieldTasksLinkIcon from "../assets/icons/field-tasks.svg";
import dangerIcon from "../assets/icons/redDanger.svg";
import successIcon from "../assets/icons/successful.svg";
import Modal from "../components/modal/Modal";
import CropZoneSuccess from "../components/field/CropZoneSuccess";
import styles from "../styles/FieldDetails.module.scss";
import filesIcon from "../assets/icons/field.svg";
import { FormButton } from "../components/form/Form";

import NewCropZone from "../components/field/NewCropZone";
import useIsMobile from "../hooks/useIsMobile";
import AddNewField from "../components/setupFarm/AddNewField";
import FullScreenModal from "../components/modal/FullScreenModal";

export default function FieldDetails() {
  const [isNewFieldModalOpen, setNewFieldModalOpen] = useState(false);
  const [isNewCropZoneModalOpen, setNewCropZoneModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [formError, setFormError] = useState<string>("");
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const handleCloseCropZoneModal = () => {
    setNewCropZoneModalOpen(false);
    setFormError("");
    setInvalidFields([]);
  };

  const [newFieldData, setNewFieldData] = useState({
    newFieldName: "",
    newFieldArea: "",
    newFieldValidFrom: "",
    newFieldValidTo: "",
    newFieldCoordinates: "",
    newFieldSize: "",
    newFieldAreaType: "",
    newFieldDescription: "",
  });

  const [fieldFormError, setFieldFormError] = useState<string>("");
  const [invalidFieldInputs, setInvalidFieldInputs] = useState<string[]>([]);
  const isMobile = useIsMobile();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isNewFieldSuccessModalOpen, setIsNewFieldSuccessModalOpen] =
    useState<boolean>(false);

  const handleCloseNewFieldModal = () => {
    setNewFieldModalOpen(false);
    setFieldFormError("");
    setInvalidFieldInputs([]);
    setNewFieldData({
      newFieldName: "",
      newFieldArea: "",
      newFieldValidFrom: "",
      newFieldValidTo: "",
      newFieldCoordinates: "",
      newFieldSize: "",
      newFieldAreaType: "",
      newFieldDescription: "",
    });
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setActiveSection(null);
      setIsClosing(false);
    }, 300);
  };

  const renderMobileModals = () => {
    if (!activeSection) {
      return null;
    }

    let content = null;
    let isFullScreen = false;
    let icon = null;

    const sectionIcons: Record<string, string> = {
      "New Field": filesIcon,
      "New Crop Zone": filesIcon,
    };

    switch (activeSection) {
      case "New Field":
        content = (
          <AddNewField closeModal={() => {}} page="" changePage={() => {}} />
        );
        break;
      case "New Crop Zone":
        content = (
          <NewCropZone
            setNewCropZoneModalOpen={setNewCropZoneModalOpen}
            setIsSuccessModalOpen={setIsSuccessModalOpen}
          />
        );
        break;
      default:
        content = null;
        break;
    }

    return (
      <FullScreenModal
        title={activeSection || ""}
        icon={sectionIcons[activeSection || ""]}
        isOpen={!!activeSection}
        isFullScreen={["New Field", "New Crop Zone"].includes(
          activeSection || ""
        )}
        onClose={handleClose}
        isClosing={isClosing}
      >
        {content}
      </FullScreenModal>
    );
  };

  return (
    <>
      <NestedLayout
        pageTitle="Field Details"
        pageIcon={fieldInformationLinkIcon}
        actionTitle="New Field"
        action={() => {
          if (isMobile) {
            setActiveSection("New Field");
          } else {
            setNewFieldModalOpen(true);
          }
        }}
        optionalActionTitle="New Crop Zone"
        optionalAction={() => {
          if (isMobile) {
            setActiveSection("New Crop Zone");
          } else {
            setNewCropZoneModalOpen(true);
          }
        }}
        navLinks={[
          {
            icon: fieldInformationLinkIcon,
            label: "Field Information",
            link: "field-information",
          },
          {
            icon: fieldTasksLinkIcon,
            label: "Field Tasks",
            link: "field-tasks",
          },
        ]}
      >
        <Outlet />
      </NestedLayout>
      {renderMobileModals()}
      <Modal
        headingTitle="Create New Field"
        isOpen={isNewFieldModalOpen}
        onClose={handleCloseNewFieldModal}
        headingIcon={filesIcon}
      >
        <AddNewField closeModal={() => {}} page="" changePage={() => {}} />
      </Modal>
      <Modal
        headingTitle=""
        isOpen={isNewFieldSuccessModalOpen}
        showCloseButton={false}
        onClose={() => {}}
        size="md"
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>New Field Created!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created a Field:{" "}
            <span>{newFieldData.newFieldName}</span>. It will now appear in your
            Field list.
          </p>
          <FormButton
            children="Got it!"
            action={() => setIsNewFieldSuccessModalOpen(false)}
          />
        </div>
      </Modal>
      <Modal
        headingTitle="Create Crop Zone"
        headingSubtitle="Create your Crop Zone"
        isOpen={isNewCropZoneModalOpen}
        onClose={handleCloseCropZoneModal}
        headingIcon={filesIcon}
      >
        <NewCropZone
          setNewCropZoneModalOpen={setNewCropZoneModalOpen}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
        />
      </Modal>
      <Modal
        isOpen={isSuccessModalOpen}
        onClose={() => {}}
        headingTitle=""
        headingSubtitle=""
        size="md"
        showCloseButton={false}
      >
        <CropZoneSuccess
          onGotIt={() => {
            setIsSuccessModalOpen(false);
            window.location.reload();
          }}
        />
      </Modal>
    </>
  );
}
