import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Form,
  FormButton,
  FormGroup,
  FormInput,
  FormSelect,
} from "../form/Form";
import styles from "../../styles/FieldDetails.module.scss";
import { validateDates, validateFields } from "../form/ValidateForms";
import dangerIcon from "../../assets/icons/redDanger.svg";

interface NewCropZoneProps {
  setNewCropZoneModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewCropZone = ({
  setNewCropZoneModalOpen,
  setIsSuccessModalOpen,
}: NewCropZoneProps) => {
  const [cropZoneData, setCropZoneData] = useState({
    cropZoneName: "",
    cropVariety: "",
    crop: "",
    cropPurpose: "",
    cropSeason: "",
    cropPerennial: "",
    seasonStart: "",
    seasonEnd: "",
    productionType: "",
    area: "",
  });

  const [formError, setFormError] = useState("");
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const fieldRefs = React.useRef<{
    [key: string]:
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement
      | null;
  }>({});

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCropZoneData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setInvalidFields((prev) => prev.filter((field) => field !== name));
  };

  const validateCropZone = () => {
    const { isValid, errorMessage, invalidFieldsArray } =
      validateFields(cropZoneData);

    const dateValidation = validateDates({
      start: cropZoneData.seasonStart,
      end: cropZoneData.seasonEnd,
      startField: "seasonStart",
      endField: "seasonEnd",
    });

    if (!dateValidation.isValid) {
      setFormError(dateValidation.errorMessage);
      setInvalidFields(dateValidation.invalidFieldsArray);
      return false;
    }

    setFormError(errorMessage);
    setInvalidFields(invalidFieldsArray);

    if (!isValid && invalidFields.length > 0) {
      fieldRefs.current[invalidFieldsArray[0]]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    return isValid;
  };

  const handleSaveCropZone = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateCropZone()) {
      return;
    }

    localStorage.setItem("cropZoneData", JSON.stringify(cropZoneData));
    setNewCropZoneModalOpen(false);
    setIsSuccessModalOpen(true);
  };

  useEffect(() => {
    if (invalidFields.length > 0) {
      fieldRefs.current[invalidFields[0]]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [invalidFields]);

  const handleCloseCropZoneModal = () => {
    setNewCropZoneModalOpen(false);
    setFormError("");
    setInvalidFields([]);
  };
  return (
    <div>
      <Form
        customCSS={{
          border: "1px solid var(--clr-lightgreen)",
          padding: "2rem",
          borderRadius: "var(--form-section-br)",
        }}
        onSubmit={handleSaveCropZone}
      >
        {formError && (
          <div className={styles["error-container"]}>
            <p className={styles["error-container__error-message"]}>
              <img src={dangerIcon} alt="" />
              {formError}
            </p>
          </div>
        )}
        <FormGroup isRow={true}>
          <FormInput
            id="new-crop-zone-name"
            name="cropZoneName"
            label="CROP ZONE NAME*"
            value={cropZoneData.cropZoneName}
            onChange={handleChange}
            inputRef={(el) => (fieldRefs.current["cropZoneName"] = el)}
            customClassName={
              invalidFields.includes("cropZoneName")
                ? styles["input-error"]
                : ""
            }
          />
          <FormSelect
            id="new-crop-zone-crop"
            name="cropVariety"
            label="VARIETY*"
            value={cropZoneData.cropVariety}
            onChange={handleChange}
            selectOptions={[]}
            inputRef={(el) => (fieldRefs.current["cropVariety"] = el)}
            selectClassName={
              invalidFields.includes("cropVariety") ? styles["input-error"] : ""
            }
          />
        </FormGroup>
        <FormGroup isRow={true}>
          <FormInput
            id="new-crop-zone-season"
            name="crop"
            label="CROP*"
            placeholder="Apple"
            value={cropZoneData.crop}
            onChange={handleChange}
            inputRef={(el) => (fieldRefs.current["crop"] = el)}
            customClassName={
              invalidFields.includes("crop") ? styles["input-error"] : ""
            }
          />
        </FormGroup>
        <FormGroup isRow={true}>
          <FormSelect
            id="new-crop-zone-area-type"
            name="cropPurpose"
            label="CROP PURPOSE"
            value={cropZoneData.cropPurpose}
            selectOptions={[]}
            onChange={handleChange}
            inputRef={(el) => (fieldRefs.current["cropPurpose"] = el)}
            selectClassName={
              invalidFields.includes("cropPurpose") ? styles["input-error"] : ""
            }
          />
          <FormSelect
            id="new-crop-zone-season-type"
            name="cropSeason"
            label="SEASON*"
            value={cropZoneData.cropSeason}
            selectOptions={[]}
            onChange={handleChange}
            inputRef={(el) => (fieldRefs.current["cropSeason"] = el)}
            selectClassName={
              invalidFields.includes("cropSeason") ? styles["input-error"] : ""
            }
          />
        </FormGroup>
        <FormGroup isRow={true}>
          <FormInput
            id="new-crop-zone-perennial"
            name="cropPerennial"
            label="PERENNIAL"
            value={cropZoneData.cropPerennial}
            onChange={handleChange}
            customCSS={{
              height: "50px",
            }}
            placeholder="Perennial"
            inputRef={(el) => (fieldRefs.current["cropPerennial"] = el)}
            customClassName={
              invalidFields.includes("cropPerennial")
                ? styles["input-error"]
                : ""
            }
          />
          <FormGroup isRow={true}>
            <FormInput
              id="new-field-valid-from"
              name="seasonStart"
              type="date"
              label="SEASON START*"
              value={cropZoneData.seasonStart}
              onChange={handleChange}
              customCSS={{
                flex: 1,
              }}
              inputRef={(el) => (fieldRefs.current["seasonStart"] = el)}
              customClassName={
                invalidFields.includes("seasonStart")
                  ? styles["input-error"]
                  : ""
              }
            />
            <FormInput
              id="new-field-valid-TO"
              name="seasonEnd"
              type="date"
              label="SEASON END*"
              value={cropZoneData.seasonEnd}
              onChange={handleChange}
              customCSS={{
                flex: 1,
              }}
              inputRef={(el) => (fieldRefs.current["seasonEnd"] = el)}
              customClassName={
                invalidFields.includes("seasonEnd") ? styles["input-error"] : ""
              }
            />
          </FormGroup>
        </FormGroup>
        <FormGroup isRow={true}>
          <FormSelect
            id="new-crop-zone-season-type"
            name="productionType"
            label="PRODUCTION TYPE"
            value={cropZoneData.productionType}
            onChange={handleChange}
            selectOptions={[]}
            inputRef={(el) => (fieldRefs.current["productionType"] = el)}
            selectClassName={
              invalidFields.includes("productionType")
                ? styles["input-error"]
                : ""
            }
          />
          <FormInput
            id="new-field-size"
            name="area"
            label="AREA"
            value={cropZoneData.area}
            onChange={handleChange}
            placeholder="Arable"
            inputRef={(el) => (fieldRefs.current["area"] = el)}
            customClassName={
              invalidFields.includes("area") ? styles["input-error"] : ""
            }
          />
        </FormGroup>
        <FormGroup isRow={true}>
          <FormButton
            isTransparent={true}
            type="button"
            action={handleCloseCropZoneModal}
          >
            Cancel
          </FormButton>
          <FormButton type="submit">Save</FormButton>
        </FormGroup>
      </Form>
    </div>
  );
};

export default NewCropZone;
